<script>
export default {
    name: 'CreateQFOrderStep1View',
};
</script>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { mapGetters } from '@/utils/vuexUtils';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import QFOrderModel from '@/models/QFOrder';
import useOrderSettingsForm from '@/composition/useOrderSettingsForm';
import PrepDateForm from '@/components/forms/PrepDateForm';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount?.organization.id;

const { currentQFOrder } = mapGetters(['currentQFOrder']);

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const checkOrderStatus = () => {
    if (currentQFOrder.value === null) {
        router.push({ name: QFO_ROUTE_NAMES.INDEX });
    }
};

const cancelOrder = () => {
    currentQFOrder.value.cancel().finally(() => {
        EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
            type: ALERT_TYPES.SUCCESS,
            message: 'Order has been successfully cancelled',
        });

        router.push({ name: QFO_ROUTE_NAMES.INDEX });

        store.commit('setCurrentQFOrder', null);
    });
};

/*------------------------------------------------------------------------
                         Order settings form state
------------------------------------------------------------------------*/

const { orderSettingsFormState, orderSettingsForm } = useOrderSettingsForm();

const navigateToNextStep = () => {
    router.push({ name: QFO_ROUTE_NAMES.CREATE.ADD_ITEMS });
}

const submitForm = (payload) => {
    let request = null;

    if (currentQFOrder.value !== null) {
        if (!orderSettingsFormState.hasChangesMade) {
            navigateToNextStep();
        } else {
            orderSettingsFormState.isSubmitting = true;

            request = currentQFOrder.value.update(payload).then((model) => {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Order settings has been successfully updated',
                });

                store.commit('setCurrentQFOrder', model);

                navigateToNextStep();
            });
        }
    } else {
        orderSettingsFormState.isSubmitting = true;

        request = QFOrderModel.create(orgId, payload).then((model) => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'New pending order has been successfully created',
            });

            store.commit('setCurrentQFOrder', model);

            navigateToNextStep();
        });
    }

    if (request !== null) {
        request
            .catch((error) => catchUnprocessableEntity(error, orderSettingsForm.setValidationErrors))
            .finally(() => (orderSettingsFormState.isSubmitting = false));
    }
};
</script>

<template>
    <OrderStepTemplate
        :is-step-submitting="orderSettingsFormState.isSubmitting"
        @before-init-cancel-process="checkOrderStatus"
        @cancel-process="cancelOrder"
    >
        <template #step-content>
            <PrepDateForm
                id="pl-create-qf-order-step-1-form"
                is-quick-fill
                :prep-date="currentQFOrder?.prep_date"
                :validation-errors="orderSettingsFormState.validationErrors"
                @update-status="orderSettingsForm.update"
                @submit="submitForm"
            />
        </template>

        <template #next-step-action>
            <BtnUI
                is-filled
                type="submit"
                form="pl-create-qf-order-step-1-form"
                :disabled="orderSettingsForm.isSubmittingDisabled()"
            >
                {{ orderSettingsForm.getSubmitBtnText(currentQFOrder) }}
            </BtnUI>
        </template>
    </OrderStepTemplate>
</template>
