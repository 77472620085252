<script>
export default {
    name: 'ItemForm',
};
</script>

<script setup>
import { reactive, ref, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { Line } from 'vue-chartjs';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { catchUnprocessableEntity } from '@/utils/httpUtils';
import { required, requiredWith, requiredIf, minLength, maxLength, minValue } from '@/utils/formValidators';
import { isFieldEmpty } from '@/utils/formUtils';
import { cutFloatNumber } from '@/utils/numberUtils';
import { getBatchUnitQtyAmount } from '@/utils/batchUnitsUtils';
import {
    getLocationAssigneeOptions,
    validateBatchSizeAmount,
    getBatchSettingsPayload,
    getBatchTimeSettingsPayload,
    calculateTotalAdvancedBatchLaborTime,
    getBatchTimeChartData,
    getBatchTimeChartOptions,
    getSelectedUnitsOptions,
    getInitCreateNewEntityOptionValue,
    getItemEntityOptions,
} from '@/utils/itemUtils';
import {
    ADVANCED_SETTINGS_OPTION,
    AVAILABLE_ORDER_TYPES,
    DEFAULT_AVAILABLE_ORDER_TYPE,
    AVAILABLE_ORDER_TYPE_OPTIONS,
    ADVANCED_BATCH_TIME_CHART_TYPES,
    ADVANCED_BATCH_TIME_CHART_TYPE_OPTIONS,
    ADVANCED_BATCH_TIME_CHART_DISPLAYS,
    ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS,
    REPORTING_UNIT_CHECKBOX_OPTIONS,
    ITEM_CONFIGURATION_ENTITIES_NAME,
} from '@/enums/itemEnums';
import { ALERT_TYPES, TAG_LIST_SELECT_EVENTS } from '@/enums/componentsEnums';
import { ACCOUNT_ROLES } from '@/enums/accountEnums';
import StationModel from '@/models/Station';
import DepartmentModel from '@/models/Department';
import LocationModel from '@/models/Location';
import PrepTaskModel from '@/models/PrepTask';
import SupplierModel from '@/models/Supplier';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useForm from '@/composition/useForm';
import useItemConfiguration from '@/composition/useItemConfiguration';
import Accordion from '@/components/Accordion';
import Switcher from '@/components/Switcher';
import Modal from '@/components/Modal';
import Alert from '@/components/Alert';
import SimpleEntityFormModal from '@/components/forms/SimpleEntityFormModal';
import PrepTaskForm from '@/components/forms/PrepTaskForm';
import TextInput from '@/components/form-controls/TextInput';
import NumericInput from '@/components/form-controls/NumericInput';
import TagListSelect from '@/components/form-controls/TagListSelect';
import TextareaControl from '@/components/form-controls/TextareaControl';
import SelectInput from '@/components/form-controls/SelectInput';
import Radio from '@/components/form-controls/Radio';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import ItemFormUI from '@/components/model-specific/item/ItemFormUI';
import PlusAltIcon from '@/components/icons/PlusAltIcon';
import AddPrepTaskModal from './partials/AddPrepTaskModal';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    batchSizeAmount: {
        type: Number,
        default: null,
    },
    batchSizeUnitId: {
        type: Number,
        default: null,
    },
    batchYieldAmount: {
        type: Number,
        default: null,
    },
    batchYieldUnitId: {
        type: Number,
        default: null,
    },
    availableOrderType: {
        type: String,
        default: DEFAULT_AVAILABLE_ORDER_TYPE,
    },
    departmentId: {
        type: Number,
        default: null,
    },
    category: {
        type: Number,
        default: null,
    },
    stations: {
        type: Array,
        default: () => [],
    },
    shelfLife: {
        type: Number,
        default: null,
    },
    locationSpecificSettings: {
        type: Array,
        default: null,
    },
    batchWeightAmount: {
        type: Number,
        default: null,
    },
    batchWeightUnit: {
        type: String,
        default: null,
    },
    batchCost: {
        type: Number,
        default: null,
    },
    batchLaborTime: {
        type: Number,
        default: null,
    },
    laborTimeSettings: {
        type: Object,
        default: null,
    },
    quickFillOrderingUnitId: {
        type: Number,
        default: null,
    },
    prepListOrderingUnitId: {
        type: Number,
        default: null,
    },
    prepTasks: {
        type: Array,
        default: () => [],
    },
    defaultReportingUnitId: {
        type: Number,
        default: null,
    },
    isChangeableReportingUnit: {
        type: Boolean,
        default: false,
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    submitBtnText: {
        type: String,
        default: 'save item',
    },
    disableAnimation: {
        type: Boolean,
        default: false,
    },
    isResetTriggered: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['reset-success', 'submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        name: '',
        batch_size_amount: null,
        batch_size_unit_id: null,
        batch_yield_amount: null,
        batch_yield_unit_id: null,
        available_order_type: props.availableOrderType,
        department_id: null,
        category: null,
        stations: [],
        shelf_life: null,
        location_specific_settings: [],
        batch_weight_amount: null,
        batch_weight_unit: null,
        batch_cost: null,
        batch_labor_time: null,
        batch_time_speedup: null,
        batch_time_initial: null,
        batch_time_final: null,
        quick_fill_ordering_unit_id: null,
        prep_list_ordering_unit_id: null,
        prep_tasks: [],
        default_reporting_unit_id: null,
        is_changeable_reporting_unit: false,
    },
    {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
        batch_size_amount: {
            required,
            minValue: minValue(1),
        },
        batch_size_unit_id: {
            required,
        },
        batch_yield_amount: {
            required,
            minValue: minValue(1),
        },
        batch_yield_unit_id: {
            required,
        },
        shelf_life: {
            minValue: minValue(1),
        },
        batch_weight_amount: {
            requiredWith: requiredWith('batch_weight_unit'),
        },
        batch_weight_unit: {
            requiredWith: requiredWith('batch_weight_amount'),
        },
        batch_cost: {
            requiredIf: requiredIf(() => !isFieldEmpty(props.batchCost)),
        },
        batch_labor_time: {
            requiredIf: requiredIf(
                () => !batchTimeSettings.isAdvancedModeActivated && !isFieldEmpty(props.batchLaborTime)
            ),
            minValue: minValue(1),
        },
        batch_time_speedup: {
            requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            minValue: minValue(2),
        },
        batch_time_initial: {
            requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            minValue: minValue(1),
        },
        batch_time_final: {
            requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            minValue: minValue(1),
        },
    },
    {
        name: 'Item name',
        batch_size_amount: 'Production output amount',
        batch_size_unit_id: 'Production output unit',
        batch_yield_amount: 'Batch yield amount',
        batch_yield_unit_id: 'Batch yield unit',
        shelf_life: 'Shelf life',
        batch_weight_amount: 'Batch weight amount',
        batch_weight_unit: 'Batch weight unit',
        batch_cost: 'Batch cost',
        batch_labor_time: 'Batch labor time',
        batch_time_speedup: 'Batch time speedup',
        batch_time_initial: 'Batch initial time',
        batch_time_final: 'Batch final time',
    }
);

const isAdvancedSettingsVisible = ref(false);

const setInitialFormState = () => {
    formState.name = props.name;
    formState.batch_size_amount = props.batchSizeAmount;
    formState.batch_size_unit_id = props.batchSizeUnitId;
    formState.batch_yield_amount = props.batchYieldAmount;
    formState.batch_yield_unit_id = props.batchYieldUnitId;
    formState.available_order_type = props.availableOrderType;
    formState.department_id = props.departmentId ?? defaultDepartmentId.value;
    formState.stations = props.stations;
    formState.shelf_life = props.shelfLife;
    formState.location_specific_settings =
        props.locationSpecificSettings ??
        generalState.locations.map(({ id, name }) => ({
            location_name: name,
            location_id: id,
            assignee_id: null,
            source_id: null,
            source_type: null,
            batch_cost: null,
            description: null,
        }));
    formState.batch_weight_amount = props.batchWeightAmount;
    formState.batch_weight_unit = props.batchWeightUnit;
    formState.batch_cost = props.batchCost;
    formState.batch_labor_time = props.batchLaborTime;
    formState.quick_fill_ordering_unit_id = props.quickFillOrderingUnitId;
    formState.prep_list_ordering_unit_id = props.prepListOrderingUnitId;
    formState.prep_tasks = props.prepTasks.map(({ id, qty, ...restData }) => ({
        id,
        BYQty: qty,
        times: qty / props.batchYieldAmount,
        ...restData,
    }));
    formState.default_reporting_unit_id = props.defaultReportingUnitId;
    formState.is_changeable_reporting_unit = props.isChangeableReportingUnit;

    const { laborTimeSettings } = props;

    if (laborTimeSettings !== null) {
        formState.batch_time_speedup = laborTimeSettings.period;
        formState.batch_time_initial = laborTimeSettings.init_time;
        formState.batch_time_final = laborTimeSettings.final_time;

        batchTimeSettings.chartType = laborTimeSettings.strategy;
        batchTimeSettings.isAdvancedModeActivated = props.prepTasks.length === 0;
    }

    nextTick(() => {
        formState.category = props.category || defaultCategoryId.value;

        activeSourceIndexes.value = formState.location_specific_settings.reduce(
            (acc, location, index) => ({
                [index]: location.source_id !== null,
                ...acc,
            }),
            {}
        );
    });
};

watch(
    () => props.isResetTriggered,
    (isResetTriggered) => {
        if (isResetTriggered) {
            setInitialFormState();

            emit('reset-success');
        }
    }
);

watch(() => props.validationErrors, form.setValidationErrors);

/*------------------------------------------------------------------------
                            Item configuration
------------------------------------------------------------------------*/

const entitiesQueryParams = {
    [ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY]: {
        with_categories: true,
        with_members: true,
        'member_role_in[0]': ACCOUNT_ROLES.COOK,
        'member_role_in[1]': ACCOUNT_ROLES.MANAGER,
    },
};

const {
    itemConfigurationState,

    activeConfigurationEntity,

    itemEntityFormState,
    openItemEntityForm,
    closeItemEntityForm,
    submitItemEntityForm,
} = useItemConfiguration(entitiesQueryParams);

const setCurrentUnitId = (id = null) => {
    if (formState.batch_yield_unit_id === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)) {
        formState.batch_yield_unit_id = id;
    } else {
        formState.batch_size_unit_id = id;
    }
};

const handleCloseItemEntityForm = () => {
    switch (activeConfigurationEntity.value) {
        case ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY:
            formState.category = defaultCategoryId.value;

            break;

        case ITEM_CONFIGURATION_ENTITIES_NAME.UNIT:
            setCurrentUnitId();

            break;
    }

    closeItemEntityForm();
};

const handleSubmitItemEntityForm = (attributes) => {
    submitItemEntityForm(attributes, (modelData) => {
        switch (activeConfigurationEntity.value) {
            case ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY: {
                const { categories } = modelData;

                formState.category = categories[categories.length - 1].id;

                break;
            }

            case ITEM_CONFIGURATION_ENTITIES_NAME.UNIT:
                setCurrentUnitId(modelData.id);

                break;

            case ITEM_CONFIGURATION_ENTITIES_NAME.STATION:
                formState.stations.unshift(modelData.id);

                break;
        }
    });
};

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    locations: [],
    prepTasks: [],
    suppliers: [],
    isDataLoading: false,
    hasError: false,
});

const loadGeneralState = () => {
    generalState.isDataLoading = true;

    Promise.all([
        sendAbortableRequest(
            DepartmentModel.all(orgId, {
                with_default: true,
                with_categories: true,
                with_members: true,
                'member_role_in[0]': ACCOUNT_ROLES.COOK,
                'member_role_in[1]': ACCOUNT_ROLES.MANAGER,
            })
        ),
        sendAbortableRequest(StationModel.all(orgId)),
        sendAbortableRequest(LocationModel.all(orgId)),
        sendAbortableRequest(PrepTaskModel.all(orgId)),
        sendAbortableRequest(SupplierModel.all(orgId)),
        store.dispatch('itemBatchMeta/loadMeta', orgId),
    ])
        .then(([departmentModels, stationModels, locationModels, prepTaskModels, supplierModels]) => {
            itemConfigurationState.departments = departmentModels || [];
            itemConfigurationState.stations = stationModels || [];

            generalState.locations = locationModels || [];
            generalState.prepTasks = prepTaskModels || [];
            generalState.suppliers = supplierModels || [];

            setInitialFormState();
        })
        .catch(() => (generalState.hasError = true))
        .finally(() => (generalState.isDataLoading = false));
};

const isLoading = computed(() => generalState.isDataLoading || props.isDataLoading || props.isSubmitting);

watch(
    () => props.isDataLoading,
    (isDataLoading) => {
        if (!isDataLoading) {
            loadGeneralState();
        }
    },
    { immediate: true }
);

/*------------------------------------------------------------------------
                           Available order type
------------------------------------------------------------------------*/

const isTypeAvailable = (type) => [type, AVAILABLE_ORDER_TYPES.BOTH].includes(formState.available_order_type);

const availableOrderTypeState = computed(() => ({
    isQuickFill: isTypeAvailable(AVAILABLE_ORDER_TYPES.QUICK_FILL),
    isPrepList: isTypeAvailable(AVAILABLE_ORDER_TYPES.PREP_LIST),
}));

watch(
    () => formState.available_order_type,
    (availableOrderType) => {
        if (availableOrderType === AVAILABLE_ORDER_TYPES.QUICK_FILL) {
            formState.prep_list_ordering_unit_id = null;
        } else if (availableOrderType === AVAILABLE_ORDER_TYPES.PREP_LIST) {
            formState.quick_fill_ordering_unit_id = null;

            formState.location_specific_settings.forEach((location, index) => {
                location.source_id = null;
                location.source_type = null;
                location.batch_cost = null;
                location.description = null;

                activeSourceIndexes.value[index] = false;

                form.clearErrors([
                    `location_specific_settings.${index}.source_id`,
                    `location_specific_settings.${index}.batch_cost`,
                    `location_specific_settings.${index}.description`,
                ]);
            });
        }
    }
);

/*------------------------------------------------------------------------
                                Department
------------------------------------------------------------------------*/

const defaultDepartmentId = computed(() => {
    const { departments } = itemConfigurationState;

    const defaultDepartment = departments.find((department) => department.is_default);

    return defaultDepartment.id;
});

const departmentOptions = computed(() =>
    itemConfigurationState.departments.map(({ id, name }) => ({
        value: id,
        text: name,
    }))
);

const selectedDepartmentState = computed(() => {
    const { departments } = itemConfigurationState;

    const department = departments.find(({ id }) => formState.department_id === id) || {};

    return {
        categories: department.categories || [],
        assignees: department.members || [],
    };
});

/*------------------------------------------------------------------------
                                 Category
------------------------------------------------------------------------*/

const defaultCategoryId = computed(() => {
    const { categories } = selectedDepartmentState.value;

    return categories.find((category) => category.is_default)?.id ?? null;
});

const categoryOptions = computed(() =>
    getItemEntityOptions(selectedDepartmentState.value.categories, ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY)
);

const handleCategoryUpdated = (value) => {
    if (value === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY)) {
        openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY, { department_id: formState.department_id });
    }

    form.clearErrors('category');
};

/*------------------------------------------------------------------------
                                 Stations
------------------------------------------------------------------------*/

const stationOptions = computed(() =>
    getItemEntityOptions(itemConfigurationState.stations, ITEM_CONFIGURATION_ENTITIES_NAME.STATION)
);

const updateStations = ({ value, type }) => {
    switch (type) {
        case TAG_LIST_SELECT_EVENTS.ADD: {
            if (value === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.STATION)) {
                openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.STATION);
            } else {
                formState.stations.unshift(value);
            }

            break;
        }

        case TAG_LIST_SELECT_EVENTS.REMOVE: {
            const index = formState.stations.findIndex((stationValue) => stationValue === value);

            formState.stations.splice(index, 1);

            break;
        }
    }
};

/*------------------------------------------------------------------------
                              Source settings
------------------------------------------------------------------------*/

const activeSourceIndexes = ref({});

const getSourceOptionValue = (index) => {
    const { source_id: id, source_type: type } = formState.location_specific_settings[index];

    if (!id) {
        return null;
    }

    return `${id} ${type}`;
};

const isLocationSourceSupplier = (index) => {
    const { source_type: type } = formState.location_specific_settings[index];

    return type && type.includes('supplier');
};

const getProductionSourceOptions = (targetId) => {
    const { locations, suppliers } = generalState;

    if (locations.length === 1 && suppliers.length === 0) {
        return [];
    }

    const options = [];

    const locationOptions = locations.reduce((acc, { id, name }) => {
        if (targetId !== id) {
            acc.push({
                value: `${id} location`,
                text: name,
            });
        }

        return acc;
    }, []);

    if (locationOptions.length > 0) {
        options.push({ text: 'Locations', groupLevel: 1 });
        options.push(...locationOptions);
    }

    if (suppliers.length > 0) {
        const supplierOptions = suppliers.map(({ id, name }) => ({
            value: `${id} supplier`,
            text: name,
        }));

        options.push({ text: 'Suppliers', groupLevel: 1 });
        options.push(...supplierOptions);
    }

    return options;
};

const handleActiveSourceChanged = (index) => {
    if (!activeSourceIndexes.value[index]) {
        formState.location_specific_settings[index].source_id = null;
        formState.location_specific_settings[index].source_type = null;
        formState.location_specific_settings[index].batch_cost = null;
        formState.location_specific_settings[index].description = null;
    }

    form.clearErrors([
        `location_specific_settings.${index}.source_id`,
        `location_specific_settings.${index}.batch_cost`,
        `location_specific_settings.${index}.description`,
    ]);
};

const handleSourceSettingsChanged = (value, index) => {
    const [id, type] = value.split(' ');

    formState.location_specific_settings[index].source_id = +id;
    formState.location_specific_settings[index].source_type = type;
    formState.location_specific_settings[index].batch_cost = null;
    formState.location_specific_settings[index].description = null;

    form.clearErrors([
        `location_specific_settings.${index}.source_id`,
        `location_specific_settings.${index}.batch_cost`,
        `location_specific_settings.${index}.description`,
    ]);
};

const validateSourceSettings = () => {
    let isValid = true;

    for (let index = 0; index < formState.location_specific_settings.length; index++) {
        if (!activeSourceIndexes.value[index]) {
            continue;
        }

        const location = formState.location_specific_settings[index];

        const errors = [];

        if (location.source_id === null) {
            errors.push({ field: 'source_id', message: 'Source is required.' });
        }

        if (isLocationSourceSupplier(index) && !location.batch_cost) {
            errors.push({ field: 'batch_cost', message: 'Batch cost is required.' });
        }

        if (errors.length > 0) {
            errors.forEach(({ field, message }) => {
                form.setErrors(`location_specific_settings.${index}.${field}`, message);
            });

            isValid = false;
        }
    }

    return isValid;
};

const isSourceSettingsAccordionVisible = computed(() => {
    if (generalState.isDataLoading || props.isDataLoading) {
        return true;
    }

    return (
        formState.available_order_type !== AVAILABLE_ORDER_TYPES.PREP_LIST &&
        (generalState.locations.length > 1 || generalState.suppliers.length > 0)
    );
});

/*------------------------------------------------------------------------
                             Default assignees
------------------------------------------------------------------------*/

watch(
    () => formState.department_id,
    () => {
        const { assignees } = selectedDepartmentState.value;

        formState.category = defaultCategoryId.value;

        if (formState.category !== null) {
            form.clearErrors('category');
        }

        const assigneeIds = new Set(assignees.map(({ id }) => id));

        formState.location_specific_settings.forEach((location, index) => {
            if (!assigneeIds.has(location.assignee_id)) {
                location.assignee_id = null;

                form.clearErrors(`location_specific_settings.${index}.assignee_id`);
            }
        });
    }
);

/*------------------------------------------------------------------------
                          General batch settings
------------------------------------------------------------------------*/

const batchUnitsState = computed(() => {
    const {
        batch_size_units: batchSizeUnits,
        batch_yield_units: batchYieldUnits,
        batch_weight_units: batchWeightUnits,
    } = store.state.itemBatchMeta.meta || {};

    return {
        sizeOptions: getItemEntityOptions(batchSizeUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT),
        yieldOptions: getItemEntityOptions(batchYieldUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT),
        weightOptions: getItemEntityOptions(batchWeightUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT, false, true),
    };
});

const handleBatchUnitUpdated = (key) => {
    if (formState[key] === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)) {
        openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT);
    } else if (key !== 'batch_size_amount' && form.hasErrors('batch_size_amount')) {
        form.validate('batch_size_amount');
    }

    form.clearErrors(key);
};

const isBatchWeightRequired = computed(
    () => !isFieldEmpty(formState.batch_weight_amount) || !isFieldEmpty(formState.batch_weight_unit)
);

watch(
    () => formState.prep_tasks,
    (value, oldValue) => {
        if (value.length === 0 && oldValue.length === 0) {
            return;
        }

        if (addedPrepTasks.value.length === 0) {
            formState.batch_cost = props.batchCost;
            formState.batch_labor_time = props.batchLaborTime;
        } else {
            const { batchCost, batchLaborTime } = addedPrepTasks.value.reduce(
                (totals, prepTask) => {
                    const {
                        batch_yield_amount: batchYieldAmount,
                        labor_time_settings: laborTimeSettings,
                        BYQty,
                    } = prepTask;

                    const batchesQty = BYQty / batchYieldAmount;

                    totals.batchCost += +prepTask.batch_cost * batchesQty;

                    if (laborTimeSettings === null) {
                        totals.batchLaborTime += +prepTask.batch_labor_time * batchesQty;
                    } else {
                        totals.batchLaborTime += calculateTotalAdvancedBatchLaborTime(
                            BYQty,
                            +laborTimeSettings.period * batchYieldAmount,
                            +laborTimeSettings.init_time,
                            +laborTimeSettings.final_time,
                            laborTimeSettings.strategy,
                            batchYieldAmount
                        );
                    }

                    return totals;
                },
                { batchCost: 0, batchLaborTime: 0 }
            );

            formState.batch_cost = cutFloatNumber(batchCost);
            formState.batch_labor_time = Math.ceil(batchLaborTime);

            batchTimeSettings.isAdvancedModeActivated = false;
        }

        form.clearErrors(['batch_cost', 'batch_labor_time']);
    },
    { deep: true }
);

/*------------------------------------------------------------------------
                       Advanced batch time settings
------------------------------------------------------------------------*/

const batchTimeSettings = reactive({
    isAdvancedModeActivated: false,
    chartType: ADVANCED_BATCH_TIME_CHART_TYPES.LINEAR,
    chartDisplay: ADVANCED_BATCH_TIME_CHART_DISPLAYS.PER_BATCH,
});

const toggleBatchTimeAdvancedMode = () => {
    batchTimeSettings.isAdvancedModeActivated = !batchTimeSettings.isAdvancedModeActivated;

    form.clearErrors(['batch_labor_time', 'batch_time_speedup', 'batch_time_initial', 'batch_time_final']);
};

const isBatchTimeChartInvalid = computed(() => {
    const fields = ['batch_time_speedup', 'batch_time_initial', 'batch_time_final'];

    return formState.prep_tasks.length === 0 && fields.some((field) => isFieldEmpty(formState[field]));
});

const batchTimeChartData = computed(() =>
    getBatchTimeChartData(
        formState,
        batchTimeSettings.chartType,
        batchTimeSettings.chartDisplay === ADVANCED_BATCH_TIME_CHART_DISPLAYS.TOTAL,
        addedPrepTasks.value
    )
);

const batchTimeChartOptions = computed(() => getBatchTimeChartOptions(isBatchTimeChartInvalid.value));

/*------------------------------------------------------------------------
                                 Ordering
------------------------------------------------------------------------*/

const resetFieldIfInvalid = (field, options) => {
    const values = new Set(options.map(({ value }) => value));

    if (!values.has(formState[field])) {
        formState[field] = null;
    }
};

const orderingUnitOptions = computed(() => getSelectedUnitsOptions(formState, batchUnitsState.value));

watch(orderingUnitOptions, (options) => {
    resetFieldIfInvalid('quick_fill_ordering_unit_id', options);
    resetFieldIfInvalid('prep_list_ordering_unit_id', options);
});

/*------------------------------------------------------------------------
                         Add prep task modal state
------------------------------------------------------------------------*/

const addPrepTaskModalState = reactive({
    isOpened: false,
    id: null,
    BYQty: 0,
});

const openAddPrepTaskModal = (prepTask) => {
    if (prepTask !== null) {
        addPrepTaskModalState.id = prepTask.id;
        addPrepTaskModalState.BYQty = prepTask.BYQty;
    }

    addPrepTaskModalState.isOpened = true;
};

const closeAddPrepTaskModal = () => {
    addPrepTaskModalState.isOpened = false;
    addPrepTaskModalState.id = null;
    addPrepTaskModalState.BYQty = 0;
};

const addPrepTask = (payload) => {
    const prepTaskIndex = formState.prep_tasks.findIndex((prepTask) => prepTask.id === addPrepTaskModalState.id);

    if (prepTaskIndex !== -1) {
        formState.prep_tasks[prepTaskIndex] = payload;
    } else {
        formState.prep_tasks.unshift(payload);
    }

    closeAddPrepTaskModal();
};

const removePrepTask = (id) => {
    const index = formState.prep_tasks.findIndex((prepTask) => prepTask.id === id);

    formState.prep_tasks.splice(index, 1);
};

const availablePrepTasks = computed(() =>
    generalState.prepTasks.filter(
        ({ id, departments }) =>
            !formState.prep_tasks.some((prepTask) => prepTask.id === id && prepTask.id !== addPrepTaskModalState.id) &&
            departments.some((department) => department.id === formState.department_id)
    )
);

const addedPrepTasks = computed(() =>
    formState.prep_tasks.map(({ id, BYQty, times }) => {
        const prepTaskData = generalState.prepTasks.find((prepTask) => prepTask.id === id);

        return {
            ...prepTaskData,
            BYQty,
            times,
            qty: getBatchUnitQtyAmount(
                prepTaskData,
                prepTaskData.batch_yield_unit,
                prepTaskData.ordering_unit,
                BYQty,
                true
            ),
        };
    })
);

watch(
    () => formState.department_id,
    (departmentId) => {
        if (formState.prep_tasks.length > 0) {
            formState.prep_tasks = addedPrepTasks.value.reduce((prepTasks, { id, BYQty, times, departments }) => {
                if (departments.some((department) => department.id === departmentId)) {
                    prepTasks.push({ id, BYQty, times });
                }

                return prepTasks;
            }, []);
        }
    }
);

watch(
    () => formState.batch_yield_amount,
    (itemBYAmount) => {
        formState.prep_tasks = addedPrepTasks.value.map(({ id, times }) => ({
            id,
            times,
            BYQty: times * itemBYAmount,
        }));
    }
);

/*------------------------------------------------------------------------
                       Create prep task modal state
------------------------------------------------------------------------*/

const createPrepTaskFormState = reactive({
    isModalOpened: false,
    isSubmitting: false,
    validationErrors: null,
    initialDepartments: [],
});

const openCreatePrepTaskModal = () => {
    createPrepTaskFormState.isModalOpened = true;
    createPrepTaskFormState.initialDepartments = [{ id: formState.department_id }];
};

const closeCreatePrepTaskModal = () => {
    createPrepTaskFormState.isModalOpened = false;
    createPrepTaskFormState.validationErrors = null;
};

const submitCreatePrepTask = (attributes) => {
    createPrepTaskFormState.isSubmitting = true;

    PrepTaskModel.create(orgId, attributes)
        .then((prepTaskData) => {
            EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                type: ALERT_TYPES.SUCCESS,
                message: 'Prep task has been successfully created',
            });

            generalState.prepTasks = [...generalState.prepTasks, prepTaskData];
            addPrepTaskModalState.id = prepTaskData.id;

            closeCreatePrepTaskModal();
        })
        .catch((error) =>
            catchUnprocessableEntity(error, (errors) => {
                createPrepTaskFormState.validationErrors = errors;
            })
        )
        .finally(() => (createPrepTaskFormState.isSubmitting = false));
};

/*------------------------------------------------------------------------
                                 Reporting
------------------------------------------------------------------------*/

const reportingUnitOptions = computed(() => {
    const defaultOption = {
        value: null,
        text: 'batches',
    };

    return getSelectedUnitsOptions(formState, batchUnitsState.value, defaultOption);
});

watch(reportingUnitOptions, (options) => {
    resetFieldIfInvalid('default_reporting_unit_id', options);
});

/*------------------------------------------------------------------------
                                Submit form
------------------------------------------------------------------------*/

const handleBatchSizeError = (errorMessage) => {
    form.setErrors('batch_size_amount', errorMessage);
};

const submitForm = () => {
    if (form.validate() && validateBatchSizeAmount(formState, handleBatchSizeError) && validateSourceSettings()) {
        const {
            batch_labor_time: batchLaborTime,
            batch_time_speedup: batchTimeSpeedup,
            batch_time_initial: batchTimeInitial,
            batch_time_final: batchTimeFinal,
            prep_tasks: prepTasks,
            ...restFormPayload
        } = formState;

        emit('submit', {
            ...restFormPayload,
            ...getBatchSettingsPayload(formState),
            ...getBatchTimeSettingsPayload(
                batchLaborTime,
                batchTimeSpeedup,
                batchTimeInitial,
                batchTimeFinal,
                batchTimeSettings.chartType,
                batchTimeSettings.isAdvancedModeActivated
            ),
            is_quick_fill: availableOrderTypeState.value.isQuickFill,
            is_prep_list: availableOrderTypeState.value.isPrepList,
            prep_tasks: prepTasks.map(({ id, BYQty }) => ({ id, qty: BYQty })),
        });
    }
};

/*------------------------------------------------------------------------
                             Accordions state
------------------------------------------------------------------------*/

const prepTasksAccordionLabel = computed(() => {
    const prepTasksCount = formState.prep_tasks.length;

    return prepTasksCount > 0 ? `Prep Tasks (${prepTasksCount})` : 'Prep Tasks';
});
</script>

<template>
    <Alert
        v-if="generalState.hasError"
        :type="ALERT_TYPES.FAIL"
    >
        Item could not be loaded for some reason. Please try again later.
    </Alert>

    <ItemFormUI
        v-else
        :disable-animation="disableAnimation"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix, accordionModifiers }">
            <div :class="classNames.spacerMd">
                <TextInput
                    v-model="formState.name"
                    include-asterisk
                    name="name"
                    label="Item name"
                    :data-test-id="`${qaPrefix}_name_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('name')"
                    @blur="form.validate('name')"
                    @update:modelValue="form.clearErrors('name')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('name')"
                    :data-testid="`${qaPrefix}_name_errors`"
                    :errors="form.getErrors('name')"
                />
            </div>

            <div :class="classNames.spacerMd">
                <div class="input-group input-group--select-mode">
                    <NumericInput
                        v-model="formState.batch_size_amount"
                        include-asterisk
                        only-positive
                        label="Production Output"
                        name="batch_size_amount"
                        hint-text="This is the output for a given batch from a cook's perspective i.e. how many bins, trays, or some other similar metric"
                        :max="9999"
                        :data-test-id="`${qaPrefix}_batch_size_amount_input`"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('batch_size_amount')"
                        @blur="form.validate('batch_size_amount')"
                        @update:modelValue="form.clearErrors('batch_size_amount')"
                    />

                    <SelectInput
                        v-model="formState.batch_size_unit_id"
                        include-asterisk
                        searchable
                        is-last-option-marked
                        label="unit"
                        :data-test-id="`${qaPrefix}_batch_size_unit_select`"
                        :disabled="isLoading"
                        :options="batchUnitsState.sizeOptions"
                        :has-error="form.hasErrors('batch_size_unit_id')"
                        @blur="form.validate('batch_size_unit_id')"
                        @update:modelValue="handleBatchUnitUpdated('batch_size_unit_id')"
                    />
                </div>

                <ValidationErrors
                    v-if="form.hasErrors('batch_size_amount')"
                    :data-testid="`${qaPrefix}_batch_size_amount_errors`"
                    :errors="form.getErrors('batch_size_amount')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('batch_size_unit_id')"
                    :data-testid="`${qaPrefix}_batch_size_unit_errors`"
                    :errors="form.getErrors('batch_size_unit_id')"
                />
            </div>

            <div :class="classNames.spacerLg">
                <div class="input-group input-group--select-mode">
                    <NumericInput
                        v-model="formState.batch_yield_amount"
                        only-positive
                        include-asterisk
                        name="batch_yield_amount"
                        label="Batch Yield"
                        hint-text="It helps you understand how many people you can feed"
                        :max="9999"
                        :data-test-id="`${qaPrefix}_batch_yield_amount_input`"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('batch_yield_amount')"
                        @blur="form.validate('batch_yield_amount')"
                        @update:modelValue="form.clearErrors('batch_yield_amount')"
                    />

                    <SelectInput
                        v-model="formState.batch_yield_unit_id"
                        searchable
                        is-last-option-marked
                        include-asterisk
                        label="unit"
                        :data-test-id="`${qaPrefix}_batch_yield_unit_select`"
                        :options="batchUnitsState.yieldOptions"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('batch_yield_unit_id')"
                        @blur="form.validate('batch_yield_unit_id')"
                        @update:modelValue="handleBatchUnitUpdated('batch_yield_unit_id')"
                    />
                </div>

                <ValidationErrors
                    v-if="form.hasErrors('batch_yield_amount')"
                    :data-testid="`${qaPrefix}_batch_yield_amount_errors`"
                    :errors="form.getErrors('batch_yield_amount')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('batch_yield_unit_id')"
                    :data-testid="`${qaPrefix}_batch_yield_unit_errors`"
                    :errors="form.getErrors('batch_yield_unit_id')"
                />
            </div>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    v-if="!generalState.isDataLoading && !isDataLoading"
                    :class="classNames.spacerSm"
                >
                    <CheckboxInput
                        v-model="isAdvancedSettingsVisible"
                        enable-boolean-mode
                        name="show-all-settings-checkbox"
                        :qa-prefix="qaPrefix"
                        :class="classNames.checkbox"
                        :options="ADVANCED_SETTINGS_OPTION"
                    />
                </div>
            </transition>

            <template v-if="isAdvancedSettingsVisible">
                <transition-group
                    appear
                    name="down"
                    type="transition"
                >
                    <Accordion
                        key="accordion: order types"
                        title="Order Types"
                        :data-test-id="`${qaPrefix}_order_types`"
                        :content-modifier-prefix="accordionModifiers.spaceLeft"
                    >
                        <label>This item is available for:</label>

                        <Radio
                            v-model="formState.available_order_type"
                            name="available-order-type"
                            :data-testid="`${qaPrefix}_order_type_radio`"
                            :options="AVAILABLE_ORDER_TYPE_OPTIONS"
                        />
                    </Accordion>

                    <Accordion
                        key="accordion: general information"
                        title="General Information"
                        :data-test-id="`${qaPrefix}_general_information`"
                        :has-error="form.hasErrors(['department_id', 'category', 'stations', 'shelf_life'])"
                    >
                        <div :class="classNames.spacerMd">
                            <SelectInput
                                v-model="formState.department_id"
                                searchable
                                include-asterisk
                                label="Department"
                                :data-test-id="`${qaPrefix}_department_select`"
                                :options="departmentOptions"
                                :has-error="form.hasErrors('department_id')"
                                @blur="form.validate('department_id')"
                                @update:modelValue="form.clearErrors('department_id')"
                            />

                            <ValidationErrors
                                v-if="form.hasErrors('department_id')"
                                :data-testid="`${qaPrefix}_department_errors`"
                                :errors="form.getErrors('department_id')"
                            />
                        </div>

                        <div
                            v-if="formState.department_id !== defaultDepartmentId"
                            :class="classNames.spacerMd"
                        >
                            <SelectInput
                                v-model="formState.category"
                                searchable
                                is-last-option-marked
                                label="Category"
                                :data-test-id="`${qaPrefix}_category_select`"
                                :options="categoryOptions"
                                :has-error="form.clearErrors('category')"
                                @blur="form.validate('category')"
                                @update:modelValue="handleCategoryUpdated"
                            />

                            <ValidationErrors
                                v-if="form.hasErrors('category')"
                                :data-testid="`${qaPrefix}_category_errors`"
                                :errors="form.getErrors('category')"
                            />
                        </div>

                        <TagListSelect
                            label="Stations"
                            is-last-option-marked
                            :data-form-control-test-id="`${qaPrefix}_station_select`"
                            :data-form-control-test-id-error="`${qaPrefix}_station_errors`"
                            :class="classNames.spacerMd"
                            :tags="formState.stations"
                            :options="stationOptions"
                            :has-error="form.hasErrors('stations')"
                            :errors="form.getErrors('stations')"
                            @update-tags-list="updateStations"
                        />

                        <NumericInput
                            v-model="formState.shelf_life"
                            only-positive
                            name="shelf_life"
                            label="Shelf Life"
                            :max="65535"
                            :data-test-id="`${qaPrefix}_shelf_life_input`"
                            :has-error="form.hasErrors('shelf_life')"
                            @blur="form.validate('shelf_life')"
                            @update:modelValue="form.clearErrors('shelf_life')"
                        />

                        <ValidationErrors
                            v-if="form.hasErrors('shelf_life')"
                            :data-testid="`${qaPrefix}_shelf_life_errors`"
                            :errors="form.getErrors('shelf_life')"
                        />
                    </Accordion>

                    <Accordion
                        v-if="isSourceSettingsAccordionVisible"
                        key="accordion: source settings"
                        title="Source settings"
                        :data-test-id="`${qaPrefix}_source_settings`"
                    >
                        <div
                            v-for="(setting, index) in formState.location_specific_settings"
                            :key="`source settings: ${index}`"
                            class="pl-item-source-setting"
                            :class="{
                                [classNames.spacerSm]: index < formState.location_specific_settings.length - 1,
                            }"
                        >
                            <CheckboxInput
                                v-model="activeSourceIndexes[index]"
                                enable-boolean-mode
                                :name="`source_settings.${index}.active`"
                                :qa-prefix="qaPrefix"
                                :options="[{ value: true }]"
                                @update:modelValue="handleActiveSourceChanged(index)"
                            />

                            <div class="pl-item-source-setting__wrapper">
                                <SelectInput
                                    searchable
                                    enable-group-mode
                                    :include-asterisk="activeSourceIndexes[index]"
                                    :model-value="getSourceOptionValue(index)"
                                    :data-test-id="`${qaPrefix}_source_select`"
                                    :label="setting.location_name"
                                    :options="getProductionSourceOptions(setting.location_id)"
                                    :has-error="form.hasErrors(`location_specific_settings.${index}.source_id`)"
                                    :disabled="!activeSourceIndexes[index]"
                                    @update:modelValue="(value) => handleSourceSettingsChanged(value, index)"
                                />

                                <ValidationErrors
                                    v-if="form.hasErrors(`location_specific_settings.${index}.source_id`)"
                                    :data-testid="`${qaPrefix}_source_id_${index}_errors`"
                                    :errors="form.getErrors(`location_specific_settings.${index}.source_id`)"
                                />
                            </div>

                            <div
                                v-if="isLocationSourceSupplier(index)"
                                class="pl-item-source-setting__additional-fields"
                            >
                                <div :class="classNames.spacerSm">
                                    <NumericInput
                                        v-model="formState.location_specific_settings[index].batch_cost"
                                        only-positive
                                        always-use-dot
                                        include-asterisk
                                        label="Batch Cost"
                                        name="source_batch_cost"
                                        :max="9999"
                                        :max-precision="2"
                                        :data-test-id="`${qaPrefix}_source_batch_cost_input`"
                                        :has-error="form.hasErrors(`location_specific_settings.${index}.batch_cost`)"
                                        @blur="form.validate(`location_specific_settings.${index}.batch_cost`)"
                                        @update:modelValue="
                                            form.clearErrors(`location_specific_settings.${index}.batch_cost`)
                                        "
                                    />

                                    <ValidationErrors
                                        v-if="form.hasErrors(`location_specific_settings.${index}.batch_cost`)"
                                        :data-testid="`${qaPrefix}_source_batch_cost_errors`"
                                        :errors="form.getErrors(`location_specific_settings.${index}.batch_cost`)"
                                    />
                                </div>

                                <TextareaControl
                                    v-model="formState.location_specific_settings[index].description"
                                    name="source_description"
                                    label="Description"
                                    :data-test-id="`${qaPrefix}_source_description_input`"
                                    :has-error="form.hasErrors(`location_specific_settings.${index}.description`)"
                                    @blur="form.validate(`location_specific_settings.${index}.description`)"
                                    @update:modelValue="
                                        form.clearErrors(`location_specific_settings.${index}.description`)
                                    "
                                />

                                <ValidationErrors
                                    v-if="form.hasErrors(`location_specific_settings.${index}.description`)"
                                    :data-testid="`${qaPrefix}_source_description_errors`"
                                    :errors="form.getErrors(`location_specific_settings.${index}.description`)"
                                />
                            </div>
                        </div>
                    </Accordion>

                    <Accordion
                        key="accordion: default assignees"
                        title="Default Assignees"
                        :data-test-id="`${qaPrefix}_default_assignees`"
                    >
                        <div
                            v-for="(setting, index) in formState.location_specific_settings"
                            :key="`default employee: ${index}`"
                            :class="{
                                [classNames.spacerSm]: index < formState.location_specific_settings.length - 1,
                            }"
                        >
                            <SelectInput
                                v-model="formState.location_specific_settings[index].assignee_id"
                                searchable
                                :data-test-id="`${qaPrefix}_default_assignee_select`"
                                :label="setting.location_name"
                                :options="
                                    getLocationAssigneeOptions(selectedDepartmentState.assignees, setting.location_id)
                                "
                                :has-error="form.hasErrors(`location_specific_settings.${index}.assignee_id`)"
                                @update:modelValue="form.clearErrors(`location_specific_settings.${index}.assignee_id`)"
                            />

                            <ValidationErrors
                                v-if="form.hasErrors(`location_specific_settings.${index}.assignee_id`)"
                                :data-testid="`${qaPrefix}_assignee_${index}_errors`"
                                :errors="form.getErrors(`location_specific_settings.${index}.assignee_id`)"
                            />
                        </div>
                    </Accordion>

                    <Accordion
                        key="accordion: batch settings"
                        title="Batch Settings"
                        :data-test-id="`${qaPrefix}_batch_settings`"
                        :has-error="
                            form.hasErrors([
                                'batch_weight_amount',
                                'batch_weight_unit',
                                'batch_cost',
                                'batch_labor_time',
                                'batch_time_speedup',
                                'batch_time_initial',
                                'batch_time_final',
                            ])
                        "
                    >
                        <div :class="classNames.spacerMd">
                            <div class="input-group input-group--select-mode">
                                <NumericInput
                                    v-model="formState.batch_weight_amount"
                                    only-positive
                                    label="Batch Weight"
                                    name="batch_weight_amount"
                                    :max="9999"
                                    :data-test-id="`${qaPrefix}_batch_weight_amount_input`"
                                    :include-asterisk="isBatchWeightRequired"
                                    :has-error="form.hasErrors('batch_weight_amount')"
                                    @blur="form.validate('batch_weight_amount')"
                                    @update:modelValue="form.clearErrors(['batch_weight_amount', 'batch_weight_unit'])"
                                />

                                <SelectInput
                                    v-model="formState.batch_weight_unit"
                                    searchable
                                    label="unit"
                                    :data-test-id="`${qaPrefix}_batch_weight_unit_select`"
                                    :include-asterisk="isBatchWeightRequired"
                                    :options="batchUnitsState.weightOptions"
                                    :has-error="form.hasErrors('batch_weight_unit')"
                                    @blur="form.validate('batch_weight_unit')"
                                    @update:modelValue="form.clearErrors(['batch_weight_amount', 'batch_weight_unit'])"
                                />
                            </div>

                            <ValidationErrors
                                v-if="form.hasErrors('batch_weight_amount')"
                                :data-testid="`${qaPrefix}_batch_weight_amount_errors`"
                                :errors="form.getErrors('batch_weight_amount')"
                            />

                            <ValidationErrors
                                v-if="form.hasErrors('batch_weight_unit')"
                                :data-testid="`${qaPrefix}_batch_weight_unit_errors`"
                                :errors="form.getErrors('batch_weight_unit')"
                            />
                        </div>

                        <div :class="classNames.spacerMd">
                            <NumericInput
                                v-model="formState.batch_cost"
                                only-positive
                                always-use-dot
                                label="Batch Cost"
                                name="batch_cost"
                                :max="9999"
                                :max-precision="2"
                                :data-test-id="`${qaPrefix}_batch_cost_input`"
                                :include-asterisk="!isFieldEmpty(batchCost)"
                                :disabled="formState.prep_tasks.length > 0"
                                :has-error="form.hasErrors('batch_cost')"
                                @blur="form.validate('batch_cost')"
                                @update:modelValue="form.clearErrors('batch_cost')"
                            />

                            <small
                                v-if="formState.prep_tasks.length > 0"
                                :class="classNames.ribbon"
                            >
                                Batch cost is calculated according to prep tasks.
                            </small>

                            <ValidationErrors
                                v-if="form.hasErrors('batch_cost')"
                                :data-testid="`${qaPrefix}_batch_cost_errors`"
                                :errors="form.getErrors('batch_cost')"
                            />
                        </div>

                        <div :class="classNames.batchTimeWrapper">
                            <template v-if="batchTimeSettings.isAdvancedModeActivated">
                                <TextInput
                                    disabled
                                    model-value="Advanced mode activated"
                                    name="batch_labor_time_advanced"
                                    label="Labor Time"
                                />

                                <button
                                    type="button"
                                    :data-test-id="`${qaPrefix}_batch_labor_time_advanced_btn_close`"
                                    @click="toggleBatchTimeAdvancedMode"
                                >
                                    <CrossIcon
                                        :height="18"
                                        :width="18"
                                    />
                                </button>
                            </template>

                            <template v-else>
                                <NumericInput
                                    v-model="formState.batch_labor_time"
                                    only-positive
                                    label="Labor Time (minutes)"
                                    name="batch_labor_time"
                                    :max="480"
                                    :data-test-id="`${qaPrefix}_batch_labor_time_input`"
                                    :include-asterisk="!isFieldEmpty(batchLaborTime)"
                                    :disabled="formState.prep_tasks.length > 0"
                                    :has-error="form.hasErrors('batch_labor_time')"
                                    @blur="form.validate('batch_labor_time')"
                                    @update:modelValue="form.clearErrors('batch_labor_time')"
                                />

                                <button
                                    v-if="formState.prep_tasks.length === 0"
                                    type="button"
                                    :data-test-id="`${qaPrefix}_batch_labor_time_advanced_btn_open`"
                                    :disabled="!isFieldEmpty(formState.batch_labor_time)"
                                    @click="toggleBatchTimeAdvancedMode"
                                >
                                    <SettingsIcon
                                        :height="30"
                                        :width="30"
                                    />
                                </button>
                            </template>
                        </div>

                        <template v-if="batchTimeSettings.isAdvancedModeActivated">
                            <div :class="classNames.spacerSm">
                                <NumericInput
                                    v-model="formState.batch_time_speedup"
                                    only-positive
                                    include-asterisk
                                    label="Speedup Period (batches)"
                                    name="batch_time_speedup"
                                    :max="10"
                                    :data-test-id="`${qaPrefix}_batch_time_speedup_input`"
                                    :has-error="form.hasErrors('batch_time_speedup')"
                                    @blur="form.validate('batch_time_speedup')"
                                    @update:modelValue="form.clearErrors('batch_time_speedup')"
                                />

                                <ValidationErrors
                                    v-if="form.hasErrors('batch_time_speedup')"
                                    :data-testid="`${qaPrefix}_batch_time_speedup_errors`"
                                    :errors="form.getErrors('batch_time_speedup')"
                                />
                            </div>

                            <div :class="classNames.spacerSm">
                                <NumericInput
                                    v-model="formState.batch_time_initial"
                                    only-positive
                                    include-asterisk
                                    label="Initial Time (minutes)"
                                    name="batch_time_initial"
                                    :max="480"
                                    :data-test-id="`${qaPrefix}_batch_time_initial_input`"
                                    :has-error="form.hasErrors('batch_time_initial')"
                                    @blur="form.validate('batch_time_initial')"
                                    @update:modelValue="form.clearErrors('batch_time_initial')"
                                />

                                <ValidationErrors
                                    v-if="form.hasErrors('batch_time_initial')"
                                    :data-testid="`${qaPrefix}_batch_time_initial_errors`"
                                    :errors="form.getErrors('batch_time_initial')"
                                />
                            </div>

                            <div :class="classNames.spacerMd">
                                <NumericInput
                                    v-model="formState.batch_time_final"
                                    only-positive
                                    include-asterisk
                                    label="Final Time (minutes)"
                                    name="batch_time_final"
                                    :max="480"
                                    :data-test-id="`${qaPrefix}_batch_time_final_input`"
                                    :has-error="form.hasErrors('batch_time_final')"
                                    @blur="form.validate('batch_time_final')"
                                    @update:modelValue="form.clearErrors('batch_time_final')"
                                />

                                <ValidationErrors
                                    v-if="form.hasErrors('batch_time_final')"
                                    :data-testid="`${qaPrefix}_batch_time_final_errors`"
                                    :errors="form.getErrors('batch_time_final')"
                                />
                            </div>

                            <div :class="classNames.batchTimeChart">
                                <div :class="classNames.batchTimeChartTypeOptions">
                                    <button
                                        v-for="{ value, text } in ADVANCED_BATCH_TIME_CHART_TYPE_OPTIONS"
                                        :key="`chart type: ${value}`"
                                        type="button"
                                        :class="{
                                            [classNames.batchTimeChartTypeOption]: true,
                                            [classNames.batchTimeChartTypeOptionActive]:
                                                batchTimeSettings.chartType === value,
                                        }"
                                        :disabled="isBatchTimeChartInvalid"
                                        @click="batchTimeSettings.chartType = value"
                                    >
                                        {{ text }}
                                    </button>
                                </div>

                                <small
                                    v-if="isBatchTimeChartInvalid"
                                    :class="classNames.ribbon"
                                >
                                    Please specify advanced settings to see the chart.
                                </small>
                            </div>
                        </template>

                        <template v-else>
                            <small
                                v-if="formState.prep_tasks.length > 0"
                                :class="classNames.ribbon"
                            >
                                Labor time is calculated according to prep tasks.
                            </small>

                            <ValidationErrors
                                v-if="form.hasErrors('batch_labor_time')"
                                :data-testid="`${qaPrefix}_batch_labor_time_errors`"
                                :errors="form.getErrors('batch_labor_time')"
                            />
                        </template>

                        <template v-if="batchTimeSettings.isAdvancedModeActivated || formState.prep_tasks.length > 0">
                            <div :class="classNames.batchTimeChartContainer">
                                <Line
                                    :data="batchTimeChartData"
                                    :options="batchTimeChartOptions"
                                />
                            </div>

                            <Switcher
                                v-if="!isBatchTimeChartInvalid"
                                v-model="batchTimeSettings.chartDisplay"
                                :options="ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS"
                            />
                        </template>
                    </Accordion>

                    <Accordion
                        key="accordion: ordering"
                        title="Ordering"
                        :data-test-id="`${qaPrefix}_ordering`"
                    >
                        <SelectInput
                            v-if="availableOrderTypeState.isQuickFill"
                            v-model="formState.quick_fill_ordering_unit_id"
                            searchable
                            size="sm"
                            label="Quick fill ordering unit"
                            :data-test-id="`${qaPrefix}_quick_fill_ordering_unit_select`"
                            :class="classNames.spacerSm"
                            :options="orderingUnitOptions"
                        />

                        <SelectInput
                            v-if="availableOrderTypeState.isPrepList"
                            v-model="formState.prep_list_ordering_unit_id"
                            searchable
                            size="sm"
                            label="Prep lists ordering unit"
                            :data-test-id="`${qaPrefix}_prep_list_ordering_unit_select`"
                            :options="orderingUnitOptions"
                        />
                    </Accordion>

                    <Accordion
                        key="accordion: prep tasks"
                        :title="prepTasksAccordionLabel"
                        :data-test-id="`${qaPrefix}_prep_tasks`"
                    >
                        <div
                            class="pl-item-prep-task"
                            :class="{
                                'pl-item-prep-task--disabled': isFieldEmpty(formState.batch_yield_amount),
                            }"
                            :data-testid="`${qaPrefix}_add_prep_task_btn`"
                            @click="openAddPrepTaskModal(null)"
                        >
                            <PlusAltIcon
                                :width="28"
                                :height="28"
                            />

                            <span>Add prep task</span>
                        </div>

                        <small
                            v-if="isFieldEmpty(formState.batch_yield_amount) && !isLoading"
                            :class="classNames.ribbon"
                        >
                            Please specify batch yield amount.
                        </small>

                        <div
                            v-for="prepTask in addedPrepTasks"
                            :key="`added prep task: ${prepTask.id}`"
                            class="pl-item-prep-task pl-item-prep-task--added"
                            :data-testid="`${qaPrefix}_added_prep_task`"
                            @click="openAddPrepTaskModal(prepTask)"
                        >
                            <span :data-testid="`${qaPrefix}_added_prep_task_info`">
                                {{ `${prepTask.name} - ${prepTask.qty} ${prepTask.ordering_unit?.text || 'batches'}` }}
                            </span>

                            <button
                                type="button"
                                :data-testid="`${qaPrefix}_added_prep_task_delete_btn`"
                                @click.stop="removePrepTask(prepTask.id)"
                            >
                                <DeleteIcon />
                            </button>
                        </div>
                    </Accordion>

                    <Accordion
                        key="accordion: reporting"
                        title="Reporting"
                        :data-test-id="`${qaPrefix}_reporting`"
                        :content-modifier-prefix="accordionModifiers.spaceLeft"
                    >
                        <label>By default, report using these units:</label>

                        <Radio
                            v-model="formState.default_reporting_unit_id"
                            name="reporting-unit"
                            :data-testid="`${qaPrefix}_default_reporting_unit_radio`"
                            :options="reportingUnitOptions"
                        />

                        <CheckboxInput
                            v-model="formState.is_changeable_reporting_unit"
                            enable-boolean-mode
                            name="reporting-unit-checkbox"
                            :qa-prefix="qaPrefix"
                            :class="`${classNames.checkbox} ${classNames.reportingCheckbox}`"
                            :options="REPORTING_UNIT_CHECKBOX_OPTIONS"
                        />
                    </Accordion>
                </transition-group>
            </template>
        </template>

        <template #actions="{ qaPrefix }">
            <BtnUI
                is-filled
                type="submit"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="isLoading || form.isDisabled()"
            >
                {{ submitBtnText }}
            </BtnUI>

            <slot
                name="delete-btn"
                :is-loading="isLoading"
            />
        </template>
    </ItemFormUI>

    <SimpleEntityFormModal
        v-if="itemEntityFormState.isModalOpened"
        :departments="itemConfigurationState.departments"
        :entity-name="activeConfigurationEntity"
        :form-data="itemEntityFormState.entityData"
        :validation-errors="itemEntityFormState.validationErrors"
        :is-submitting="itemEntityFormState.isSubmitting"
        @submit="handleSubmitItemEntityForm"
        @close="handleCloseItemEntityForm"
    />

    <Modal
        v-if="createPrepTaskFormState.isModalOpened"
        disable-click-outside-mechanism
        :is-close-disabled="createPrepTaskFormState.isSubmitting"
        @close="closeCreatePrepTaskModal"
    >
        <template #title>
            Create new Prep Task
        </template>

        <template #content>
            <PrepTaskForm
                enable-lock-initial-departments
                :departments="createPrepTaskFormState.initialDepartments"
                :validation-errors="createPrepTaskFormState.validationErrors"
                :is-submitting="createPrepTaskFormState.isSubmitting"
                @submit="submitCreatePrepTask"
            >
                <template #delete-btn="{ isLoading: isPrepTaskLoading }">
                    <BtnUI
                        :disabled="isPrepTaskLoading"
                        @click="closeCreatePrepTaskModal"
                    >
                        Cancel
                    </BtnUI>
                </template>
            </PrepTaskForm>
        </template>
    </Modal>

    <AddPrepTaskModal
        v-else-if="addPrepTaskModalState.isOpened"
        :item-batch-yield-amount="formState.batch_yield_amount"
        :prep-tasks="availablePrepTasks"
        :prep-task-id="addPrepTaskModalState.id"
        :prep-task-batch-yield-qty="addPrepTaskModalState.BYQty"
        @close="closeAddPrepTaskModal"
        @open-create-prep-task-modal="openCreatePrepTaskModal"
        @add-prep-task="addPrepTask"
    />
</template>

<style lang="scss" scoped>
.pl-item-source-setting {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    &__wrapper {
        flex: 1;
    }

    &__additional-fields {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        margin-top: custom-space(0.5);
    }

    :deep(.pl-checkbox) {
        margin-top: custom-space(1);

        .form-check {
            margin: 0;
        }

        .form-check-input[type='checkbox'] {
            border-color: $black;
            border-radius: custom-space(0.25);
        }
    }

    &:not(:last-child) &__additional-fields {
        margin-bottom: custom-space(0.5);
        padding-bottom: custom-space(1);
        border-bottom: 1px solid $gray-400;
    }
}

.pl-item-prep-task {
    display: flex;
    align-items: center;
    gap: custom-space(1);
    padding: custom-space(0.5) custom-space(0.8);
    border: 1px solid $gray-500;
    border-radius: custom-space(2);
    cursor: pointer;

    &--added {
        justify-content: space-between;
        gap: custom-space(1.25);
        margin-top: custom-space(0.25);
        cursor: auto;

        span,
        svg {
            cursor: pointer;
        }

        span {
            font-size: $font-size-base * 0.95;
            font-weight: $font-weight-normal;
        }

        svg {
            flex-shrink: 0;
        }
    }

    &--disabled {
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
    }

    &:nth-of-type(2) {
        margin-top: custom-space(0.5);
    }
}
</style>
