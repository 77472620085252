<script>
export default {
    name: 'TextareaControl',
};
</script>

<script setup>
defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    includeAsterisk: {
        type: Boolean,
        default: false,
    },
    dataTestId: {
        type: String,
        default: null,
    },
});

const emit = defineEmits(['update:modelValue', 'blur', 'focus']);
</script>

<template>
    <div
        class="pl-textearea-control"
        :class="{
            'pl-textearea-control--filled': !!modelValue,
            'pl-textearea-control--disabled': disabled,
            'pl-textearea-control--has-label': !!label,
        }"
    >
        <label
            v-if="label"
            class="form-label"
            :class="{
                'form-label--required': includeAsterisk,
            }"
        >
            {{ label }}
        </label>

        <div class="pl-textearea-control__wrapper">
            <textarea
                ref="textareaRef"
                class="form-control"
                rows="3"
                maxlength="255"
                :class="{ 'is-invalid': hasError }"
                :value="modelValue"
                :name="name"
                :disabled="disabled"
                :data-testid="dataTestId"
                @blur="emit('blur')"
                @focus="emit('focus')"
                @input="(event) => emit('update:modelValue', event.target.value)"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-textearea-control {
    position: relative;
    padding-top: custom-space(0.25);

    > .form-label {
        position: relative;
        font-size: $font-size-base * 0.75;
        line-height: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--required::after {
            content: '*';
            position: absolute;
            top: 50%;
            color: $red-light;
            font-size: $font-size-base;
            line-height: $font-size-base * 0.75;
            padding-left: custom-space(0.1);
            transform: translateY(-50%);
        }
    }

    &__wrapper {
        margin-top: custom-space(0.25);
        border-radius: $border-radius;
        overflow: hidden;

        > .form-control {
            padding: custom-space(0.5) custom-space(0.75);
            border-radius: $border-radius;
            resize: none;
            font-size: $font-size-base;
            line-height: normal;
        }
    }

    &--filled > &__wrapper > .form-control,
    &--filled > &__wrapper > .form-control:disabled,
    &--filled > &__wrapper > .form-control[disabled] {
        border-color: $black;
    }

    &--disabled {
        opacity: $input-disabled-opacity;
    }
}
</style>
