import ApiClient from '@/plugins/ApiClient';
import { mapLeafs } from '@/utils/treeUtils';
import { isObject } from '@/utils/objectUtils';
import Model from './Model';

export default class PrepTask extends Model {
    static make(attributes = {}) {
        if (Array.isArray(attributes)) {
            return attributes.map((nested) => new this(nested));
        } else if (isObject(attributes) && attributes.id !== undefined) {
            return new this(attributes);
        }

        return mapLeafs(attributes, (item) => new this(item));
    }

    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/prep-tasks`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static find(orgId, prepTaskId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/prep-tasks/${prepTaskId}`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/prep-tasks`, attributes);

        return this.make(data);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(`organizations/${this.organization_id}/prep-tasks/${this.id}`, attributes);

        return this.fill(data);
    }

    async delete() {
        return ApiClient.delete(`organizations/${this.organization_id}/prep-tasks/${this.id}`);
    }

    static async downloadTemplate(orgId) {
        const { data } = await ApiClient.get(`organizations/${orgId}/prep-tasks/import-get-template`, {
            responseType: 'blob',
        });

        return data;
    }

    static async parseImportFile(orgId, formData) {
        const { data } = await ApiClient.post(`organizations/${orgId}/prep-tasks/import-file`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return data;
    }

    static async applyImport(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/prep-tasks/import`, { data: attributes });

        return data;
    }
}
