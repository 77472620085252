import { ORDER_TYPES } from '@/enums/orderEnums';

/*------------------------------------------------------------------------
                                  General
------------------------------------------------------------------------*/

export const ITEM_TYPES = {
    ITEM: 'item',
    PREP_TASK: 'prep_task',
    PREP_ACTION: 'prep_action',
};

export const ADVANCED_SETTINGS_OPTION = [
    { value: true, text: 'Show all settings', qaSuffix: 'show_all_settings_checkbox' },
];

/*------------------------------------------------------------------------
                           Available order type
------------------------------------------------------------------------*/

export const AVAILABLE_ORDER_TYPES = {
    QUICK_FILL: 'quick_fill',
    PREP_LIST: 'prep_list',
    BOTH: 'both',
};

export const DEFAULT_AVAILABLE_ORDER_TYPE = AVAILABLE_ORDER_TYPES.BOTH;

export const AVAILABLE_ORDER_TYPE_OPTIONS = [
    {
        value: AVAILABLE_ORDER_TYPES.QUICK_FILL,
        text: 'Quick Fill Orders',
    },
    {
        value: AVAILABLE_ORDER_TYPES.PREP_LIST,
        text: 'Prep Lists',
    },
    {
        value: AVAILABLE_ORDER_TYPES.BOTH,
        text: 'Both',
    },
];

/*------------------------------------------------------------------------
                       Advanced batch time settings
------------------------------------------------------------------------*/

export const ADVANCED_BATCH_TIME_CHART_TYPES = {
    LINEAR: 'linear',
    EASE_IN: 'ease_in',
    EASE_OUT: 'ease_out',
};

export const ADVANCED_BATCH_TIME_CHART_TYPE_OPTIONS = [
    { value: ADVANCED_BATCH_TIME_CHART_TYPES.LINEAR, text: 'Linear' },
    { value: ADVANCED_BATCH_TIME_CHART_TYPES.EASE_IN, text: 'Ease in' },
    { value: ADVANCED_BATCH_TIME_CHART_TYPES.EASE_OUT, text: 'Ease out' },
];

export const ADVANCED_BATCH_TIME_CHART_DISPLAYS = {
    PER_BATCH: 'per-batch',
    TOTAL: 'total',
};

export const ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS = [
    { value: ADVANCED_BATCH_TIME_CHART_DISPLAYS.PER_BATCH, text: 'Per batch' },
    { value: ADVANCED_BATCH_TIME_CHART_DISPLAYS.TOTAL, text: 'Total' },
];

/*------------------------------------------------------------------------
                                Prep tasks
------------------------------------------------------------------------*/

export const CREATE_NEW_PREP_TASK_OPTION_VALUE = 'create-new-prep-task';

/*------------------------------------------------------------------------
                                 Reporting
------------------------------------------------------------------------*/

export const REPORTING_UNIT_CHECKBOX_OPTIONS = [
    {
        value: true,
        text: 'Let the cook change unit when reporting',
        qaSuffix: 'is_reporting_unit_changeable_checkbox',
    },
];

/*------------------------------------------------------------------------
                                 Group by
------------------------------------------------------------------------*/

export const ITEMS_GROUP_BY = {
    DEPARTMENT_N_CATEGORY: 'department_n_category',
    DEPARTMENT: 'department',
    CATEGORY: 'category',
    STATION: 'station',
};

export const ITEMS_DEFAULT_GROUP_BY = ITEMS_GROUP_BY.DEPARTMENT_N_CATEGORY;

/*------------------------------------------------------------------------
                        Item configuration entities
------------------------------------------------------------------------*/

export const ITEM_CONFIGURATION_ENTITIES_NAME = {
    CATEGORY: 'category',
    UNIT: 'unit',
    STATION: 'station',
};

/*------------------------------------------------------------------------
                          Filter settings config
------------------------------------------------------------------------*/

export const ITEMS_FILTER_SETTINGS_CONFIG = {
    group_by: {
        title: 'Group By',
        options: [
            { value: ITEMS_GROUP_BY.DEPARTMENT_N_CATEGORY, text: 'Department & category' },
            { value: ITEMS_GROUP_BY.DEPARTMENT, text: 'Department' },
            { value: ITEMS_GROUP_BY.CATEGORY, text: 'Category' },
            { value: ITEMS_GROUP_BY.STATION, text: 'Station' },
        ],
        defaultValue: ITEMS_DEFAULT_GROUP_BY,
        component: 'select',
    },
    order_type: {
        title: 'Order Type',
        options: [
            { value: ORDER_TYPES.QUICK_FILL, text: 'Quick Fill' },
            { value: ORDER_TYPES.PREP_LIST, text: 'Prep List' },
        ],
        defaultValue: null,
    },
};
