<script>
export default {
    name: 'PrepTaskForm',
};
</script>

<script setup>
import { reactive, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { Line } from 'vue-chartjs';

import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { isFieldEmpty } from '@/utils/formUtils';
import { maxLength, minLength, minValue, required, requiredIf, requiredWith } from '@/utils/formValidators';
import {
    getLocationAssigneeOptions,
    validateBatchSizeAmount,
    getBatchSettingsPayload,
    getBatchTimeSettingsPayload,
    getBatchTimeChartData,
    getBatchTimeChartOptions,
    getSelectedUnitsOptions,
    getInitCreateNewEntityOptionValue,
    getItemEntityOptions,
} from '@/utils/itemUtils';
import { ACCOUNT_ROLES } from '@/enums/accountEnums';
import {
    ADVANCED_SETTINGS_OPTION,
    ADVANCED_BATCH_TIME_CHART_TYPES,
    ADVANCED_BATCH_TIME_CHART_TYPE_OPTIONS,
    ADVANCED_BATCH_TIME_CHART_DISPLAYS,
    ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS,
    REPORTING_UNIT_CHECKBOX_OPTIONS,
    ITEM_CONFIGURATION_ENTITIES_NAME,
} from '@/enums/itemEnums';
import { ALERT_TYPES, TAG_LIST_SELECT_EVENTS } from '@/enums/componentsEnums';
import { PREP_TASK_TIME_DELAY } from '@/enums/prepTaskEnums';
import DepartmentModel from '@/models/Department';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useForm from '@/composition/useForm';
import useItemConfiguration from '@/composition/useItemConfiguration';
import Accordion from '@/components/Accordion';
import Switcher from '@/components/Switcher';
import SimpleEntityFormModal from '@/components/forms/SimpleEntityFormModal';
import TextInput from '@/components/form-controls/TextInput';
import NumericInput from '@/components/form-controls/NumericInput';
import TagListSelect from '@/components/form-controls/TagListSelect';
import SelectInput from '@/components/form-controls/SelectInput';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import Radio from '@/components/form-controls/Radio';
import ItemFormUI from '@/components/model-specific/item/ItemFormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    departments: {
        type: Array,
        default: () => [],
    },
    timeDelay: {
        type: String,
        default: '0',
    },
    locationSpecificSettings: {
        type: Array,
        default: null,
    },
    batchSizeAmount: {
        type: Number,
        default: null,
    },
    batchSizeUnitId: {
        type: Number,
        default: null,
    },
    batchYieldAmount: {
        type: Number,
        default: null,
    },
    batchYieldUnitId: {
        type: Number,
        default: null,
    },
    batchWeightAmount: {
        type: Number,
        default: null,
    },
    batchWeightUnit: {
        type: String,
        default: null,
    },
    batchCost: {
        type: Number,
        default: null,
    },
    batchLaborTime: {
        type: Number,
        default: null,
    },
    laborTimeSettings: {
        type: Object,
        default: null,
    },
    orderingUnitId: {
        type: Number,
        default: null,
    },
    defaultReportingUnitId: {
        type: Number,
        default: null,
    },
    isChangeableReportingUnit: {
        type: Boolean,
        default: false,
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isEditMode: {
        type: Boolean,
        default: false,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    enableLockInitialDepartments: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        name: '',
        departments: [],
        time_delay: null,
        location_specific_settings: [],
        batch_size_amount: null,
        batch_size_unit_id: null,
        batch_yield_amount: null,
        batch_yield_unit_id: null,
        batch_weight_amount: null,
        batch_weight_unit: null,
        batch_cost: null,
        batch_labor_time: null,
        batch_time_speedup: null,
        batch_time_initial: null,
        batch_time_final: null,
        ordering_unit_id: null,
        default_reporting_unit_id: null,
        is_changeable_reporting_unit: false,
    },
    {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(255),
        },
        departments: {
            required,
        },
        time_delay: {
            required,
        },
        batch_size_amount: {
            required,
            minValue: minValue(1),
        },
        batch_size_unit_id: {
            required,
        },
        batch_yield_amount: {
            required,
            minValue: minValue(1),
        },
        batch_yield_unit_id: {
            required,
        },
        batch_weight_amount: {
            requiredWith: requiredWith('batch_weight_unit'),
        },
        batch_weight_unit: {
            requiredWith: requiredWith('batch_weight_amount'),
        },
        batch_cost: {
            required,
        },
        batch_labor_time: {
            requiredIf: requiredIf(() => !batchTimeSettings.isAdvancedModeActivated),
            minValue: minValue(1),
        },
        batch_time_speedup: {
            requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            minValue: minValue(2),
        },
        batch_time_initial: {
            requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            minValue: minValue(1),
        },
        batch_time_final: {
            requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            minValue: minValue(1),
        },
    },
    {
        name: 'Prep task name',
        departments: 'Department',
        time_delay: 'Time delay',
        batch_size_amount: 'Production output amount',
        batch_size_unit_id: 'Production output unit',
        batch_yield_amount: 'Batch yield amount',
        batch_yield_unit_id: 'Batch yield unit',
        batch_weight_amount: 'Batch weight amount',
        batch_weight_unit: 'Batch weight unit',
        batch_cost: 'Batch cost',
        batch_labor_time: 'Batch labor time',
        batch_time_speedup: 'Batch time speedup',
        batch_time_initial: 'Batch initial time',
        batch_time_final: 'Batch final time',
    }
);

const isAdvancedSettingsVisible = ref(false);

watch(() => props.validationErrors, form.setValidationErrors);

/*------------------------------------------------------------------------
                            Item configuration
------------------------------------------------------------------------*/

const {
    itemConfigurationState,

    activeConfigurationEntity,

    itemEntityFormState,
    openItemEntityForm,
    closeItemEntityForm,
    submitItemEntityForm,
} = useItemConfiguration();

const setCurrentUnitId = (id = null) => {
    if (formState.batch_yield_unit_id === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)) {
        formState.batch_yield_unit_id = id;
    } else {
        formState.batch_size_unit_id = id;
    }
};

const handleCloseItemEntityForm = () => {
    setCurrentUnitId();

    closeItemEntityForm();
};

const handleSubmitItemEntityForm = (attributes) => {
    submitItemEntityForm(attributes, (modelData) => {
        setCurrentUnitId(modelData.id);
    });
};

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    locations: [],
    isDataLoading: false,
});

const loadGeneralState = () => {
    generalState.isDataLoading = true;

    Promise.all([
        sendAbortableRequest(
            DepartmentModel.all(orgId, {
                with_default: true,
                with_members: true,
                'member_role_in[0]': ACCOUNT_ROLES.COOK,
                'member_role_in[1]': ACCOUNT_ROLES.MANAGER,
            })
        ),
        sendAbortableRequest(LocationModel.all(orgId)),
        store.dispatch('itemBatchMeta/loadMeta', orgId),
    ])
        .then(([departmentModels, locationModels]) => {
            itemConfigurationState.departments = departmentModels || [];
            generalState.locations = locationModels || [];

            formState.name = props.name;
            formState.departments = props.departments.map(({ id }) => id);
            formState.time_delay = props.timeDelay;
            formState.location_specific_settings =
                props.locationSpecificSettings ??
                generalState.locations.map(({ id, name }) => ({
                    location_name: name,
                    location_id: id,
                    assignee_id: null,
                }));
            formState.batch_size_amount = props.batchSizeAmount;
            formState.batch_size_unit_id = props.batchSizeUnitId;
            formState.batch_yield_amount = props.batchYieldAmount;
            formState.batch_yield_unit_id = props.batchYieldUnitId;
            formState.batch_weight_amount = props.batchWeightAmount;
            formState.batch_weight_unit = props.batchWeightUnit;
            formState.batch_cost = props.batchCost;
            formState.batch_labor_time = props.batchLaborTime;
            formState.ordering_unit_id = props.orderingUnitId;
            formState.default_reporting_unit_id = props.defaultReportingUnitId;
            formState.is_changeable_reporting_unit = props.isChangeableReportingUnit;

            const { laborTimeSettings } = props;

            if (laborTimeSettings !== null) {
                formState.batch_time_speedup = laborTimeSettings.period;
                formState.batch_time_initial = laborTimeSettings.init_time;
                formState.batch_time_final = laborTimeSettings.final_time;

                batchTimeSettings.chartType = laborTimeSettings.strategy;
                batchTimeSettings.isAdvancedModeActivated = true;
            }

            if (formState.departments.length === 0) {
                formState.departments.push(defaultDepartmentId.value);
            }
        })
        .finally(() => (generalState.isDataLoading = false));
};

const isLoading = computed(() => generalState.isDataLoading || props.isDataLoading || props.isSubmitting);

watch(
    () => props.isDataLoading,
    (isDataLoading) => {
        if (!isDataLoading) {
            loadGeneralState();
        }
    },
    { immediate: true }
);

/*------------------------------------------------------------------------
                                Departments
------------------------------------------------------------------------*/

const defaultDepartmentId = computed(() => {
    const { departments } = itemConfigurationState;

    const defaultDepartment = departments.find((department) => department.is_default);

    return defaultDepartment.id;
});

const departmentOptions = computed(() => {
    const { enableLockInitialDepartments, departments } = props;

    return itemConfigurationState.departments.map(({ id, name }) => ({
        value: id,
        text: name,
        isLocked: enableLockInitialDepartments && departments.some((department) => department.id === id),
    }));
});

const updateDepartments = ({ value, type }) => {
    switch (type) {
        case TAG_LIST_SELECT_EVENTS.ADD: {
            formState.departments.unshift(value);

            form.clearErrors('departments');

            break;
        }

        case TAG_LIST_SELECT_EVENTS.REMOVE: {
            const { can_be_deleted: canBeDeleted } = props.departments.find(({ id }) => id === value) || {};

            if (canBeDeleted === undefined || canBeDeleted) {
                const index = formState.departments.findIndex((departmentValue) => departmentValue === value);

                formState.departments.splice(index, 1);
            } else {
                EventBus.emit(EVENT_BUS_EVENTS.NOTIFICATION_FLASH, {
                    type: ALERT_TYPES.FAIL,
                    message: 'This department has an associated item, it can not be deleted.',
                });
            }

            break;
        }
    }
};

/*------------------------------------------------------------------------
                                Time delay
------------------------------------------------------------------------*/

const timeDelayOptions = computed(() =>
    Object.entries(PREP_TASK_TIME_DELAY).map(([value, text]) => ({
        value,
        text,
    }))
);

/*------------------------------------------------------------------------
                             Default assignees
------------------------------------------------------------------------*/

const availableAssignees = computed(() => {
    const selectedDepartmentIds = new Set(formState.departments.map((id) => +id));

    const uniqueAssigneeIds = new Set();
    const uniqueAssignees = [];

    itemConfigurationState.departments
        .filter(({ id }) => selectedDepartmentIds.has(id))
        .flatMap(({ members }) => members)
        .forEach((assignee) => {
            if (!uniqueAssigneeIds.has(assignee.id)) {
                uniqueAssigneeIds.add(assignee.id);
                uniqueAssignees.push(assignee);
            }
        });

    return uniqueAssignees;
});

watch(
    () => formState.departments,
    () => {
        const assigneeIds = new Set(availableAssignees.value.map(({ id }) => id));

        formState.location_specific_settings.forEach((location, index) => {
            if (!assigneeIds.has(location.assignee_id)) {
                location.assignee_id = null;

                form.clearErrors(`location_specific_settings.${index}.assignee_id`);
            }
        });
    },
    { deep: true }
);

/*------------------------------------------------------------------------
                          General batch settings
------------------------------------------------------------------------*/

const batchUnitsState = computed(() => {
    const {
        batch_size_units: batchSizeUnits,
        batch_yield_units: batchYieldUnits,
        batch_weight_units: batchWeightUnits,
    } = store.state.itemBatchMeta.meta || {};

    return {
        sizeOptions: getItemEntityOptions(batchSizeUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT),
        yieldOptions: getItemEntityOptions(batchYieldUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT),
        weightOptions: getItemEntityOptions(batchWeightUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT, false, true),
    };
});

const handleBatchUnitUpdated = (key) => {
    if (formState[key] === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)) {
        openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT);
    } else if (key !== 'batch_size_amount' && form.hasErrors('batch_size_amount')) {
        form.validate('batch_size_amount');
    }

    form.clearErrors(key);
};

const isBatchWeightRequired = computed(
    () => !isFieldEmpty(formState.batch_weight_amount) || !isFieldEmpty(formState.batch_weight_unit)
);

/*------------------------------------------------------------------------
                       Advanced batch time settings
------------------------------------------------------------------------*/

const batchTimeSettings = reactive({
    isAdvancedModeActivated: false,
    chartType: ADVANCED_BATCH_TIME_CHART_TYPES.LINEAR,
    chartDisplay: ADVANCED_BATCH_TIME_CHART_DISPLAYS.PER_BATCH,
});

const isBatchTimeChartInvalid = computed(() =>
    ['batch_time_speedup', 'batch_time_initial', 'batch_time_final'].some((field) => isFieldEmpty(formState[field]))
);

const batchTimeChartData = computed(() =>
    getBatchTimeChartData(
        formState,
        batchTimeSettings.chartType,
        batchTimeSettings.chartDisplay === ADVANCED_BATCH_TIME_CHART_DISPLAYS.TOTAL
    )
);

const batchTimeChartOptions = computed(() => getBatchTimeChartOptions(isBatchTimeChartInvalid.value));

/*------------------------------------------------------------------------
                                 Ordering
------------------------------------------------------------------------*/

const resetFieldIfInvalid = (field, options) => {
    const values = new Set(options.map(({ value }) => value));

    if (!values.has(formState[field])) {
        formState[field] = options[0].value;
    }
};

const orderingUnitOptions = computed(() => getSelectedUnitsOptions(formState, batchUnitsState.value));

watch(orderingUnitOptions, (options) => {
    resetFieldIfInvalid('ordering_unit_id', options);
});

/*------------------------------------------------------------------------
                                 Reporting
------------------------------------------------------------------------*/

const reportingUnitOptions = computed(() => {
    const defaultOption = {
        value: null,
        text: 'batches',
    };

    return getSelectedUnitsOptions(formState, batchUnitsState.value, defaultOption);
});

watch(reportingUnitOptions, (options) => {
    resetFieldIfInvalid('default_reporting_unit_id', options);
});

/*------------------------------------------------------------------------
                                Submit form
------------------------------------------------------------------------*/

const handleBatchSizeError = (errorMessage) => {
    form.setErrors('batch_size_amount', errorMessage);
};

const submitForm = () => {
    if (form.validate() && validateBatchSizeAmount(formState, handleBatchSizeError)) {
        const {
            batch_labor_time: batchLaborTime,
            batch_time_speedup: batchTimeSpeedup,
            batch_time_initial: batchTimeInitial,
            batch_time_final: batchTimeFinal,
            ...restFormPayload
        } = formState;

        emit('submit', {
            ...restFormPayload,
            ...getBatchSettingsPayload(formState),
            ...getBatchTimeSettingsPayload(
                batchLaborTime,
                batchTimeSpeedup,
                batchTimeInitial,
                batchTimeFinal,
                batchTimeSettings.chartType,
                batchTimeSettings.isAdvancedModeActivated
            ),
        });
    }
};
</script>

<template>
    <ItemFormUI
        is-prep-task
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix, accordionModifiers }">
            <div :class="classNames.spacerMd">
                <TextInput
                    v-model="formState.name"
                    include-asterisk
                    name="name"
                    label="Task name"
                    :data-test-id="`${qaPrefix}_name_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('name')"
                    @blur="form.validate('name')"
                    @update:modelValue="form.clearErrors('name')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('name')"
                    :data-testid="`${qaPrefix}_name_errors`"
                    :errors="form.getErrors('name')"
                />
            </div>

            <div :class="classNames.spacerMd">
                <div class="input-group input-group--select-mode">
                    <NumericInput
                        v-model="formState.batch_size_amount"
                        only-positive
                        include-asterisk
                        name="batch_size_amount"
                        label="Production Output"
                        hint-text="This is the output for a given batch from a cook's perspective i.e. how many bins, trays, or some other similar metric"
                        :max="9999"
                        :data-test-id="`${qaPrefix}_batch_size_amount_input`"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('batch_size_amount')"
                        @blur="form.validate('batch_size_amount')"
                        @update:modelValue="form.clearErrors('batch_size_amount')"
                    />

                    <SelectInput
                        v-model="formState.batch_size_unit_id"
                        searchable
                        is-last-option-marked
                        include-asterisk
                        label="unit"
                        :data-test-id="`${qaPrefix}_batch_size_unit_select`"
                        :disabled="isLoading"
                        :options="batchUnitsState.sizeOptions"
                        :has-error="form.hasErrors('batch_size_unit_id')"
                        @blur="form.validate('batch_size_unit_id')"
                        @update:modelValue="handleBatchUnitUpdated('batch_size_unit_id')"
                    />
                </div>

                <ValidationErrors
                    v-if="form.hasErrors('batch_size_amount')"
                    :data-testid="`${qaPrefix}_batch_size_amount_errors`"
                    :errors="form.getErrors('batch_size_amount')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('batch_size_unit_id')"
                    :data-testid="`${qaPrefix}_batch_size_unit_errors`"
                    :errors="form.getErrors('batch_size_unit_id')"
                />
            </div>

            <div :class="classNames.spacerMd">
                <div class="input-group input-group--select-mode">
                    <NumericInput
                        v-model="formState.batch_yield_amount"
                        only-positive
                        include-asterisk
                        name="batch_yield_amount"
                        label="Batch Yield"
                        hint-text="It helps you understand how many people you can feed"
                        :max="9999"
                        :data-test-id="`${qaPrefix}_batch_yield_amount_input`"
                        :disabled="isLoading"
                        :has-error="form.hasErrors('batch_yield_amount')"
                        @blur="form.validate('batch_yield_amount')"
                        @update:modelValue="form.clearErrors('batch_yield_amount')"
                    />

                    <SelectInput
                        v-model="formState.batch_yield_unit_id"
                        searchable
                        is-last-option-marked
                        include-asterisk
                        label="unit"
                        :data-test-id="`${qaPrefix}_batch_yield_unit_select`"
                        :disabled="isLoading"
                        :options="batchUnitsState.yieldOptions"
                        :has-error="form.hasErrors('batch_yield_unit_id')"
                        @blur="form.validate('batch_yield_unit_id')"
                        @update:modelValue="handleBatchUnitUpdated('batch_yield_unit_id')"
                    />
                </div>

                <ValidationErrors
                    v-if="form.hasErrors('batch_yield_amount')"
                    :data-testid="`${qaPrefix}_batch_yield_amount_errors`"
                    :errors="form.getErrors('batch_yield_amount')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('batch_yield_unit_id')"
                    :data-testid="`${qaPrefix}_batch_yield_unit_errors`"
                    :errors="form.getErrors('batch_yield_unit_id')"
                />
            </div>

            <div :class="classNames.spacerMd">
                <NumericInput
                    v-model="formState.batch_cost"
                    only-positive
                    always-use-dot
                    include-asterisk
                    name="batch_cost"
                    label="Batch Cost"
                    :max="9999"
                    :max-precision="2"
                    :data-test-id="`${qaPrefix}_batch_cost_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('batch_cost')"
                    @blur="form.validate('batch_cost')"
                    @update:modelValue="form.clearErrors('batch_cost')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('batch_cost')"
                    :data-testid="`${qaPrefix}_batch_cost_errors`"
                    :errors="form.getErrors('batch_cost')"
                />
            </div>

            <div :class="classNames.spacerLg">
                <TextInput
                    v-if="batchTimeSettings.isAdvancedModeActivated"
                    disabled
                    model-value="Advanced mode activated"
                    name="batch_labor_time_advanced"
                    label="Labor Time"
                />

                <NumericInput
                    v-else
                    v-model="formState.batch_labor_time"
                    only-positive
                    include-asterisk
                    label="Labor Time (minutes)"
                    name="batch_labor_time"
                    :max="480"
                    :data-test-id="`${qaPrefix}_batch_labor_time_input`"
                    :disabled="isLoading"
                    :has-error="form.hasErrors('batch_labor_time')"
                    @blur="form.validate('batch_labor_time')"
                    @update:modelValue="form.clearErrors('batch_labor_time')"
                />

                <ValidationErrors
                    v-if="form.hasErrors('batch_labor_time')"
                    :data-testid="`${qaPrefix}_batch_labor_time_errors`"
                    :errors="form.getErrors('batch_labor_time')"
                />
            </div>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    v-if="!generalState.isDataLoading && !isDataLoading"
                    :class="classNames.spacerSm"
                >
                    <CheckboxInput
                        v-model="isAdvancedSettingsVisible"
                        enable-boolean-mode
                        name="show-all-settings-checkbox"
                        :qa-prefix="qaPrefix"
                        :class="classNames.checkbox"
                        :options="ADVANCED_SETTINGS_OPTION"
                    />
                </div>
            </transition>

            <template v-if="isAdvancedSettingsVisible">
                <Accordion
                    title="General Information"
                    :data-test-id="`${qaPrefix}_general_information`"
                    :has-error="form.hasErrors(['departments', 'time_delay'])"
                >
                    <TagListSelect
                        include-asterisk
                        label="Departments"
                        :data-form-control-test-id="`${qaPrefix}_departments_select`"
                        :data-form-control-test-id-error="`${qaPrefix}_departments_errors`"
                        :class="classNames.spacerMd"
                        :tags="formState.departments"
                        :options="departmentOptions"
                        :errors="form.getErrors('departments')"
                        :has-error="form.hasErrors('departments')"
                        @blur="form.validate('departments')"
                        @update-tags-list="updateDepartments"
                    />

                    <SelectInput
                        v-model="formState.time_delay"
                        include-asterisk
                        searchable
                        label="Time delay"
                        :data-test-id="`${qaPrefix}_time_delay_select`"
                        :options="timeDelayOptions"
                        :has-error="form.hasErrors('time_delay')"
                        @blur="form.validate('time_delay')"
                        @update:modelValue="form.clearErrors('time_delay')"
                    />

                    <ValidationErrors
                        v-if="form.hasErrors('time_delay')"
                        :data-testid="`${qaPrefix}_time_delay_errors`"
                        :errors="form.getErrors('time_delay')"
                    />
                </Accordion>

                <Accordion
                    title="Default Assignees"
                    :data-test-id="`${qaPrefix}_default_assignees`"
                >
                    <div
                        v-for="(setting, index) in formState.location_specific_settings"
                        :key="`default employee: ${index}`"
                        :class="{
                            [classNames.spacerSm]: index < formState.location_specific_settings.length - 1,
                        }"
                    >
                        <SelectInput
                            v-model="formState.location_specific_settings[index].assignee_id"
                            searchable
                            :data-test-id="`${qaPrefix}_default_assignee_select`"
                            :label="setting.location_name"
                            :disabled="formState.departments.length === 0"
                            :options="getLocationAssigneeOptions(availableAssignees, setting.location_id)"
                            :has-error="form.hasErrors(`location_specific_settings.${index}.assignee_id`)"
                            @update:modelValue="form.clearErrors(`location_specific_settings.${index}.assignee_id`)"
                        />

                        <small
                            v-if="formState.departments.length === 0"
                            :class="classNames.ribbon"
                        >
                            Please select a department.
                        </small>

                        <ValidationErrors
                            v-if="form.hasErrors(`location_specific_settings.${index}.assignee_id`)"
                            :data-testid="`${qaPrefix}_assignee_${index}_errors`"
                            :errors="form.getErrors(`location_specific_settings.${index}.assignee_id`)"
                        />
                    </div>
                </Accordion>

                <Accordion
                    title="Batch Settings"
                    :data-test-id="`${qaPrefix}_batch_settings`"
                    :has-error="
                        form.hasErrors([
                            'batch_weight_amount',
                            'batch_weight_unit',
                            'batch_time_speedup',
                            'batch_time_initial',
                            'batch_time_final',
                        ])
                    "
                >
                    <div :class="classNames.spacerLg">
                        <div class="input-group input-group--select-mode">
                            <NumericInput
                                v-model="formState.batch_weight_amount"
                                only-positive
                                name="batch_weight_amount"
                                label="Batch Weight"
                                :max="9999"
                                :data-test-id="`${qaPrefix}_batch_weight_amount_input`"
                                :include-asterisk="isBatchWeightRequired"
                                :has-error="form.hasErrors('batch_weight_amount')"
                                @blur="form.validate('batch_weight_amount')"
                                @update:modelValue="form.clearErrors(['batch_weight_amount', 'batch_weight_unit'])"
                            />

                            <SelectInput
                                v-model="formState.batch_weight_unit"
                                searchable
                                label="unit"
                                :data-test-id="`${qaPrefix}_batch_weight_unit_select`"
                                :include-asterisk="isBatchWeightRequired"
                                :options="batchUnitsState.weightOptions"
                                :has-error="form.hasErrors('batch_weight_unit')"
                                :disabled="batchUnitsState.weightOptions.length === 0"
                                @blur="form.validate('batch_weight_unit')"
                                @update:modelValue="form.clearErrors(['batch_weight_amount', 'batch_weight_unit'])"
                            />
                        </div>

                        <ValidationErrors
                            v-if="form.hasErrors('batch_weight_amount')"
                            :data-testid="`${qaPrefix}_batch_weight_amount_errors`"
                            :errors="form.getErrors('batch_weight_amount')"
                        />

                        <ValidationErrors
                            v-if="form.hasErrors('batch_weight_unit')"
                            :data-testid="`${qaPrefix}_batch_weight_unit_errors`"
                            :errors="form.getErrors('batch_weight_unit')"
                        />
                    </div>

                    <CheckboxInput
                        v-model="batchTimeSettings.isAdvancedModeActivated"
                        enable-boolean-mode
                        name="advanced-labor-time-settings-checkbox"
                        :qa-prefix="qaPrefix"
                        :class="{
                            [classNames.checkbox]: true,
                            [classNames.spacerSm]: batchTimeSettings.isAdvancedModeActivated,
                        }"
                        :options="[
                            {
                                value: true,
                                text: 'Advanced Labor Time Settings',
                                qaSuffix: 'advanced_labor_time_settings_checkbox',
                            },
                        ]"
                        @update:modelValue="
                            form.clearErrors([
                                'batch_labor_time',
                                'batch_time_speedup',
                                'batch_time_initial',
                                'batch_time_final',
                            ])
                        "
                    />

                    <template v-if="batchTimeSettings.isAdvancedModeActivated">
                        <div :class="classNames.spacerSm">
                            <NumericInput
                                v-model="formState.batch_time_speedup"
                                only-positive
                                include-asterisk
                                label="Speedup Period (batches)"
                                name="batch_time_speedup"
                                :max="10"
                                :data-test-id="`${qaPrefix}_batch_time_speedup_input`"
                                :has-error="form.hasErrors('batch_time_speedup')"
                                @blur="form.validate('batch_time_speedup')"
                                @update:modelValue="form.clearErrors('batch_time_speedup')"
                            />

                            <ValidationErrors
                                v-if="form.hasErrors('batch_time_speedup')"
                                :data-testid="`${qaPrefix}_batch_time_speedup_errors`"
                                :errors="form.getErrors('batch_time_speedup')"
                            />
                        </div>

                        <div :class="classNames.spacerSm">
                            <NumericInput
                                v-model="formState.batch_time_initial"
                                only-positive
                                include-asterisk
                                label="Initial Time (minutes)"
                                name="batch_time_initial"
                                :max="480"
                                :data-test-id="`${qaPrefix}_batch_time_initial_input`"
                                :has-error="form.hasErrors('batch_time_initial')"
                                @blur="form.validate('batch_time_initial')"
                                @update:modelValue="form.clearErrors('batch_time_initial')"
                            />

                            <ValidationErrors
                                v-if="form.hasErrors('batch_time_initial')"
                                :data-testid="`${qaPrefix}_batch_time_initial_errors`"
                                :errors="form.getErrors('batch_time_initial')"
                            />
                        </div>

                        <div :class="classNames.spacerMd">
                            <NumericInput
                                v-model="formState.batch_time_final"
                                only-positive
                                include-asterisk
                                label="Final Time (minutes)"
                                name="batch_time_final"
                                :max="480"
                                :data-test-id="`${qaPrefix}_batch_time_final_input`"
                                :has-error="form.hasErrors('batch_time_final')"
                                @blur="form.validate('batch_time_final')"
                                @update:modelValue="form.clearErrors('batch_time_final')"
                            />

                            <ValidationErrors
                                v-if="form.hasErrors('batch_time_final')"
                                :data-testid="`${qaPrefix}_batch_time_final_errors`"
                                :errors="form.getErrors('batch_time_final')"
                            />
                        </div>

                        <div :class="classNames.batchTimeChart">
                            <div :class="classNames.batchTimeChartTypeOptions">
                                <button
                                    v-for="{ value, text } in ADVANCED_BATCH_TIME_CHART_TYPE_OPTIONS"
                                    :key="`chart type: ${value}`"
                                    type="button"
                                    :class="{
                                        [classNames.batchTimeChartTypeOption]: true,
                                        [classNames.batchTimeChartTypeOptionActive]:
                                            batchTimeSettings.chartType === value,
                                    }"
                                    :disabled="isBatchTimeChartInvalid"
                                    @click="batchTimeSettings.chartType = value"
                                >
                                    {{ text }}
                                </button>
                            </div>

                            <small
                                v-if="isBatchTimeChartInvalid"
                                :class="classNames.ribbon"
                            >
                                Please specify advanced settings to see the chart.
                            </small>

                            <div :class="classNames.batchTimeChartContainer">
                                <Line
                                    :data="batchTimeChartData"
                                    :options="batchTimeChartOptions"
                                />
                            </div>

                            <Switcher
                                v-if="!isBatchTimeChartInvalid"
                                v-model="batchTimeSettings.chartDisplay"
                                :options="ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS"
                            />
                        </div>
                    </template>
                </Accordion>

                <Accordion
                    title="Ordering"
                    :data-test-id="`${qaPrefix}_ordering`"
                >
                    <SelectInput
                        v-model="formState.ordering_unit_id"
                        searchable
                        size="sm"
                        label="Ordering unit"
                        :data-test-id="`${qaPrefix}_ordering_unit_select`"
                        :options="orderingUnitOptions"
                    />
                </Accordion>

                <Accordion
                    title="Reporting"
                    :data-test-id="`${qaPrefix}_reporting`"
                    :content-modifier-prefix="accordionModifiers.spaceLeft"
                >
                    <label>By default, report using these units:</label>

                    <Radio
                        v-model="formState.default_reporting_unit_id"
                        name="reporting-unit"
                        :data-testid="`${qaPrefix}_default_reporting_unit_radio`"
                        :options="reportingUnitOptions"
                    />

                    <CheckboxInput
                        v-model="formState.is_changeable_reporting_unit"
                        enable-boolean-mode
                        name="reporting-unit-checkbox"
                        :qa-prefix="qaPrefix"
                        :class="`${classNames.checkbox} ${classNames.reportingCheckbox}`"
                        :options="REPORTING_UNIT_CHECKBOX_OPTIONS"
                    />
                </Accordion>
            </template>
        </template>

        <template #actions="{ qaPrefix }">
            <BtnUI
                is-filled
                type="submit"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="isLoading || form.isDisabled()"
            >
                Save prep task
            </BtnUI>

            <slot
                name="delete-btn"
                :is-loading="isLoading"
            />
        </template>
    </ItemFormUI>

    <SimpleEntityFormModal
        v-if="itemEntityFormState.isModalOpened"
        :departments="itemConfigurationState.departments"
        :entity-name="activeConfigurationEntity"
        :form-data="itemEntityFormState.entityData"
        :validation-errors="itemEntityFormState.validationErrors"
        :is-submitting="itemEntityFormState.isSubmitting"
        @submit="handleSubmitItemEntityForm"
        @close="handleCloseItemEntityForm"
    />
</template>

<style lang="scss" scoped>
.pl-form > :deep(.pl-overlay-spinner) {
    border-radius: $border-radius;
}
</style>
