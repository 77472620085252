<script>
export default {
    name: 'CreateOrderFromTemplateForm',
};
</script>

<script setup>
import { reactive, onBeforeMount, watch, computed } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import { isDateSameOrBefore, isDateSameOrAfter } from '@/utils/dateUtils';
import { required } from '@/utils/formValidators';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PARTemplateModel from '@/models/PARTemplate';
import QFPARTemplateModel from '@/models/QFPARTemplate';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useForm from '@/composition/useForm';
import Alert from '@/components/Alert';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import SelectInput from '@/components/form-controls/SelectInput';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    validationErrors: {
        type: Object,
        default: null,
    },
    isQuickFill: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update-status', 'submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const { formState, ...form } = useForm(
    {
        template_id: null,
        prep_date: null,
    },
    {
        template_id: {
            required,
        },
        prep_date: {
            required,
        },
    },
    {
        template_id: 'Template',
        prep_date: props.isQuickFill ? 'Delivery Date' : 'Prep Date',
    }
);

const submitForm = () => {
    if (form.validate()) {
        emit('submit', formState);
    }
};

watch(() => props.validationErrors, form.setValidationErrors);

watch(
    () => [formState.template_id, formState.prep_date],
    (fields) => {
        const isAnyFieldNotFilled = fields.some((field) => field === null);

        emit('update-status', { isSubmittingAllowed: !isAnyFieldNotFilled });
    },
    { immediate: true }
);

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    templates: [],
    hasError: false,
    isDataLoading: true,
});

const loadTemplates = () => {
    const templateModel = props.isQuickFill ? QFPARTemplateModel : PARTemplateModel;

    sendAbortableRequest(templateModel.all(orgId, { only_completed: true }))
        .then((models) => (generalState.templates = models || []))
        .catch(() => (generalState.hasError = true))
        .finally(() => (generalState.isDataLoading = false));
};

onBeforeMount(loadTemplates);

/*------------------------------------------------------------------------
                                 Template
------------------------------------------------------------------------*/

const templateOptions = computed(() =>
    generalState.templates.map(({ id, name }) => ({
        value: id,
        text: name,
    }))
);

const selectedTemplateSettings = computed(() => {
    const { template_id: templateId } = formState;

    if (templateId === null) {
        return null;
    }

    const { settings } = generalState.templates.find(({ id }) => templateId === id);

    return {
        days: settings.days,
        dates: settings.dates || [],
        startDate: settings.start_date,
        endDate: settings.end_date,
    };
});

const isDateDisabled = (calendarDate) => {
    if (selectedTemplateSettings.value === null) {
        return false;
    }

    const { days, dates, startDate, endDate } = selectedTemplateSettings.value;

    const dateObj = dayjs(calendarDate).startOf('day');

    const isDayDateIncluded = days.includes(+dateObj.format('d'));

    if (days.length > 0 && !isDayDateIncluded) {
        return true;
    }

    if (startDate && endDate) {
        return !(isDateSameOrAfter(dateObj, startDate) && isDateSameOrBefore(dateObj, endDate));
    }

    if (dates.length > 0) {
        return !dates.some((date) => dateObj.isSame(date));
    }

    return !isDayDateIncluded;
};

watch(
    () => formState.template_id,
    () => {
        if (isDateDisabled(formState.prep_date)) {
            formState.prep_date = null;

            form.clearErrors('prep_date');
        }
    }
);
</script>

<template>
    <Alert
        v-if="generalState.hasError"
        :type="ALERT_TYPES.FAIL"
    >
        Something went wrong during loading data. Please try again later.
    </Alert>

    <FormUI
        v-else
        disable-animation
        class="pl-create-order-from-template-form"
        data-test-id="order-from-template-form"
        :is-loading="generalState.isDataLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <div :class="classNames.spacerMd">
                <SelectInput
                    v-model="formState.template_id"
                    include-asterisk
                    searchable
                    label="Template"
                    :data-test-id="`${qaPrefix}_template_select`"
                    :options="templateOptions"
                    :disabled="templateOptions.length === 0"
                    :has-error="form.hasErrors('template_id')"
                    @blur="form.validate('template_id')"
                    @update:modelValue="form.clearErrors('template_id')"
                />

                <Alert
                    v-if="templateOptions.length === 0 && !generalState.isDataLoading"
                    :type="ALERT_TYPES.FAIL"
                >
                    There are no available templates.
                </Alert>

                <ValidationErrors
                    v-else-if="form.hasErrors('template_id')"
                    :data-testid="`${qaPrefix}_template_error`"
                    :errors="form.getErrors('template_id')"
                />
            </div>

            <div
                class="pl-create-order-from-template-form__datepicker"
                :class="{
                    'pl-create-order-from-template-form__datepicker--selected': formState.prep_date !== null,
                }"
            >
                <h5>{{ isQuickFill ? 'Delivery Date' : 'Prep Date' }}</h5>

                <DatePickerHOC
                    v-model="formState.prep_date"
                    inline
                    :min-date="new Date()"
                    :disabled="formState.template_id === null"
                    :disabled-dates="isDateDisabled"
                    @update:modelValue="form.clearErrors('prep_date')"
                />
            </div>

            <small
                v-if="formState.template_id === null"
                :class="classNames.ribbon"
            >
                Please select a template.
            </small>

            <ValidationErrors
                v-if="form.hasErrors('prep_date')"
                :data-testid="`${qaPrefix}_prep_date_error`"
                :errors="form.getErrors('prep_date')"
            />
        </template>
    </FormUI>
</template>

<style lang="scss" scoped>
.pl-create-order-from-template-form {
    @include media-breakpoint-up(md) {
        max-width: 360px;
    }

    :deep(.pl-select) {
        max-width: 100%;
    }

    :deep(.pl-alert) {
        margin-top: custom-space(0.25);
    }

    &__datepicker {
        background-color: $white;
        padding: custom-space(0.5);
        border: 1px solid $form-select-border-color;
        border-radius: $border-radius;

        & > h5 {
            text-align: center;
            margin-bottom: custom-space(0.5);
        }

        :deep(.dp__menu) {
            flex-grow: 1;
        }

        :deep(.dp__calendar_header),
        :deep(.dp__calendar) {
            width: 100%;
        }

        & + small {
            text-align: center;
        }

        &--selected {
            border-color: $input-focus-border-color;
        }
    }
}
</style>
