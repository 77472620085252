<script>
export default {
    name: 'PrepDateForm',
};
</script>

<script setup>
import { watch } from 'vue';

import { isDateSameOrAfter, formatDate } from '@/utils/dateUtils';
import { required } from '@/utils/formValidators';
import useForm from '@/composition/useForm';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    prepDate: {
        type: String,
        default: null,
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isQuickFill: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update-status', 'submit']);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const getInitialPrepDate = () => {
    const { prepDate } = props;

    if (prepDate === null) {
        return formatDate();
    }

    return isDateSameOrAfter(prepDate) ? prepDate : null;
};

const { formState, ...form } = useForm(
    {
        prep_date: getInitialPrepDate(),
    },
    {
        prep_date: {
            required,
        },
    },
    {
        prep_date: props.isQuickFill ? 'Delivery Date' : 'Prep Date',
    }
);

const submitForm = () => {
    if (form.validate()) {
        emit('submit', formState);
    }
};

watch(
    () => props.validationErrors,
    (errors) => {
        if (errors !== null) {
            form.setValidationErrors(errors);

            emit('update-status', { isSubmittingAllowed: false });
        }
    },
    { immediate: true }
);

watch(
    () => formState.prep_date,
    (prepDate) => {
        emit('update-status', {
            isSubmittingAllowed: prepDate !== null,
            hasChangesMade: props.prepDate !== prepDate,
        });
    },
    { immediate: true }
);
</script>

<template>
    <FormUI
        disable-animation
        class="pl-prep-date-from"
        data-test-id="prep-date-form"
        @submit="submitForm"
    >
        <template #content="{ qaPrefix }">
            <div
                class="pl-prep-date-from__datepicker"
                :class="{
                    'pl-prep-date-from__datepicker--selected': formState.prep_date !== null,
                }"
            >
                <h5>{{ isQuickFill ? 'Delivery Date' : 'Prep Date' }}</h5>

                <DatePickerHOC
                    v-model="formState.prep_date"
                    inline
                    :min-date="new Date()"
                    @update:modelValue="form.clearErrors('prep_date')"
                />
            </div>

            <ValidationErrors
                v-if="form.hasErrors('prep_date')"
                :data-testid="`${qaPrefix}_prep_date_errors`"
                :errors="form.getErrors('prep_date')"
            />
        </template>
    </FormUI>
</template>

<style lang="scss" scoped>
.pl-prep-date-from {
    @include media-breakpoint-up(md) {
        max-width: 360px;
    }

    &__datepicker {
        background-color: $white;
        padding: custom-space(0.5);
        border: 1px solid $form-select-border-color;
        border-radius: $border-radius;

        & > h5 {
            text-align: center;
            margin-bottom: custom-space(0.5);
        }

        :deep(.dp__menu) {
            flex-grow: 1;
        }

        :deep(.dp__calendar_header),
        :deep(.dp__calendar) {
            width: 100%;
        }

        &--selected {
            border-color: $input-focus-border-color;
        }
    }
}
</style>
