import EventBus, { EVENT_BUS_EVENTS } from '@/plugins/EventBus';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';

export const catchUnprocessableEntity = (error, callback) => {
    const { status, data = {} } = error.response || {};

    if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
        callback(data.errors || data.data?.errors);
    }
};

export const catchOrderPrepDateGoneError = (error) =>
    catchUnprocessableEntity(error, (errors) => {
        if ('prep_date' in errors) {
            EventBus.emit(EVENT_BUS_EVENTS.GLOBAL_MODAL, {
                title: 'Error occurred',
                message: Object.values(errors).flat().join('\r\n'),
            });
        }
    });

export const catchNotFoundError = (error, callback) => {
    const { status } = error.response || {};

    if (status === HTTP_RESPONSES_CODE.NOT_FOUND) {
        callback();
    }
};

export const catchForbiddenError = (error, callback) => {
    const { status, data } = error.response || {};

    if (status === HTTP_RESPONSES_CODE.FORBIDDEN) {
        callback(data.message);
    }
};
